import { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  CircularProgress,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip,
} from "@mui/material";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import IOdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import {
  ProductionOrder,
  ProductionOrderLine,
  ProductSerial,
  Tracking,
} from "./erp/manufacturing/Product";
import ProductionOrderLineEditor from "./ProductionOrderLineEditor";
import {
  BF_NAKED_DOCK,
  BF_NAKED_DOT,
  canCopySerialNumber,
  isScannableLotProduct,
} from "./Utils";
import { Alert } from "@mui/material";
import NewSerialButton from "./actions/NewSerialButton";
import GetDockIdButton from "./GetDockIdButton";
import SelectSensorDotButton from "./SelectSensorDotButton";
import { isScrapped } from "./LocationUtils";
import {
  AddNewRecordCommand,
  GetProduct,
  RemoveAllRecordsCommand,
  StockMove as OdooStockMove,
  StockMoveLine,
  StockQuant,
} from "@byteflies/odoo-typescript";
import { id, VIRT_PRODUCTION, WH_STOCK } from "./erp/odoo/OdooUtils";
import { getDefaultSerialSetting } from "./Settings";
import DeviceIdField from "./DeviceIdField";
import { Serials } from "./SerialSelection";

export function getNextSequence(productionOrder: ProductionOrder): number {
  const sequences = productionOrder.lines
    .map((line) => line as ProductionOrderLine)
    .filter((s) => s.sequence !== undefined)
    .map((l) => l.sequence);

  let seq = 0;
  for (const s of sequences) {
    if (s > seq) {
      seq = s;
    }
  }
  return seq + 1;
}

interface LoadDefaultLotsPropsProps {
  svc: IOdooOperationsService;
  productionOrder: ProductionOrder | undefined;
  serials: ProductSerial[] | undefined;
  onChange(modifiedLines: ProductionOrderLine[]): void;
  onError(error: Error): void;
}

function LoadDefaultLots(props: LoadDefaultLotsPropsProps) {
  const [busy, setBusy] = useState(false);
  const { svc, productionOrder, serials, onChange, onError } = props;

  return (
    <Tooltip title="Automatically selects all bookmarked lot numbers">
      <span>
        <IconButton
          data-cy="load-default-lot-numbers"
          disabled={
            busy ||
            productionOrder === undefined ||
            productionOrder.lines === undefined ||
            productionOrder.lines.length === 0 ||
            serials === undefined ||
            serials.length === 0 ||
            productionOrder.lines
              .filter((line) => line.product !== undefined)
              .filter(
                (line) => !isScannableLotProduct(line.product.internalReference)
              )
              .filter(
                (line) =>
                  line.product?.inventory?.traceability.tracking ===
                  Tracking.ByLots
              )
              .filter(
                (line) =>
                  line.serial === undefined ||
                  (line.serial !== undefined && line.serial?.id === undefined)
              ).length === 0
          }
          onClick={async () => {
            const loadModifiedLines = async function (
              po: ProductionOrder
            ): Promise<ProductionOrderLine[]> {
              const modifiedLines: ProductionOrderLine[] = [];
              const linesWithoutSerial = po.lines
                .filter((line) => line.product !== undefined)
                .filter(
                  (line) =>
                    !isScannableLotProduct(line.product.internalReference)
                )
                .filter(
                  (line) =>
                    line.product?.inventory?.traceability.tracking ===
                    Tracking.ByLots
                )
                .filter(
                  (line) =>
                    line.serial === undefined ||
                    (line.serial !== undefined && line.serial?.id === undefined)
                );

              const allSerials = await svc.getSerialsForProducts(
                linesWithoutSerial.map((l) => l.product)
              );

              for (const line of linesWithoutSerial) {
                const productId = line.product?.id;
                const d = getDefaultSerialSetting(productId);
                const serials = allSerials.filter(
                  (s) => s.product?.id === productId
                );
                if (serials !== undefined) {
                  if (serials.length === 1) {
                    line.serial = serials[0];
                    modifiedLines.push(line);
                  } else if (d !== undefined) {
                    const extSerial = serials
                      .filter((s) => s !== undefined)
                      .filter((s) => s.id !== undefined);
                    for (const serial of extSerial) {
                      const serialId = serial.id;
                      if (serialId !== undefined && d === serialId) {
                        line.serial = serial;
                        modifiedLines.push(line);
                      }
                    }
                  }
                }
              }
              return modifiedLines;
            };

            try {
              setBusy(true);
              // These are the stock move lines where the serial number is updated
              const modifiedLines = await loadModifiedLines(productionOrder!);
              let changedLines = 0;
              for (const modifiedLine of modifiedLines) {
                if (
                  modifiedLine.serial !== undefined &&
                  modifiedLine.serial.serial !== undefined
                ) {
                  if (
                    Array.isArray(modifiedLine.move_line_ids) &&
                    modifiedLine.move_line_ids.length === 1 &&
                    typeof modifiedLine.move_line_ids[0] === "number"
                  ) {
                    // The serial number of the stock.move.line is updated,
                    // persist the stock.move.line directly
                    const stockMoveLineId = modifiedLine.move_line_ids[0];
                    const stockMoveLine = {
                      lot_id: modifiedLine.serial?.id,
                      qty_done: modifiedLine.product_qty,
                    } as StockMoveLine;
                    await svc.writeStockMoveLine(
                      stockMoveLineId,
                      stockMoveLine
                    );

                    changedLines++;
                  } else {
                    // A stock.move.line should be added to the stock.move
                    const stockMoveLine = {
                      company_id: modifiedLine.company_id,
                      product_id: modifiedLine.product?.id,
                      location_dest_id: 15,
                      location_id: 8,
                      lot_id: modifiedLine.serial?.id,
                      move_id: false,
                      owner_id: false,
                      package_id: false,
                      product_uom_id: 1 as any,
                      qty_done: modifiedLine.product_qty,
                      result_package_id: false,
                    } as StockMoveLine;
                    const clearCmd: RemoveAllRecordsCommand = [5, 0, 0];
                    const addCmd: AddNewRecordCommand<StockMoveLine> = [
                      0,
                      0,
                      stockMoveLine,
                    ];

                    const stockMoveId = modifiedLine.id;
                    const updatedStockMove: OdooStockMove = {
                      move_line_ids: [clearCmd, addCmd],
                    } as OdooStockMove;

                    await svc.writeStockMove(stockMoveId, updatedStockMove);

                    changedLines++;
                  }
                }
              }

              if (changedLines > 0) {
                onChange(modifiedLines);
              }
            } catch (e) {
              onError(e as Error);
            } finally {
              setBusy(false);
            }
          }}
        >
          {busy ? <CircularProgress size="1em" /> : <BookmarkAddedIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
}

interface ProductionOrderEditorProps {
  svc: IOdooOperationsService;
  productionOrder: ProductionOrder | undefined;
  onChange(productionOrder: ProductionOrder): void;
}

function ProductionOrderEditor(props: ProductionOrderEditorProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [serials, setSerials] = useState<ProductSerial[]>();
  const [stockQuants, setStockQuants] = useState<StockQuant[]>();
  const { svc, productionOrder, onChange } = props;
  const [nfcBusy, setNfcBusy] = useState(false);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    async function readLots(po: ProductionOrder) {
      try {
        const products = po.lines
          .map((line) => line.product)
          .filter((product) => product !== undefined)
          .filter(
            (product) => !isScannableLotProduct(product.internalReference)
          )
          .filter(
            (product) =>
              product.inventory?.traceability.tracking === Tracking.ByLots
          )
          .filter((p) => p !== undefined && p.id !== undefined)
          .map((p) => {
            return { id: p.id } as GetProduct;
          });
        const s = await svc.searchSerialsForProducts(products);
        setSerials(s);
      } catch (error) {
        console.error("error getting serials", error);
        setSerials([]);
      }
    }
    if (
      productionOrder !== undefined &&
      productionOrder.lines !== undefined &&
      productionOrder.lines.length > 0
    ) {
      readLots(productionOrder);
    }
  }, [productionOrder, svc, setSerials]);

  useEffect(() => {
    async function readStockQuants(s: ProductSerial[]) {
      try {
        const sq = await svc.searchStockQuantsBySerials(s, [WH_STOCK]);
        setStockQuants(sq);
      } catch (error) {
        console.error("error getting stock quants", error);
        setStockQuants([]);
      }
    }
    if (serials !== undefined && serials.length !== 0) {
      readStockQuants(serials);
    }
  }, [serials, svc]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div>
        <Typography variant="h6" color="inherit">
          Ingredients
        </Typography>

        <Table aria-label="bill-of-material" size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "2em" }}>Amount</TableCell>
              <TableCell style={{ width: "10em" }}>BF-number</TableCell>
              <TableCell style={{ width: "30em" }}>Product</TableCell>
              <TableCell style={{ width: "30em" }}>
                Lot/serial number{"\t\t\t"}
                <LoadDefaultLots
                  productionOrder={productionOrder}
                  svc={svc}
                  serials={serials}
                  onError={(error) => {
                    console.error(
                      "error loading default lots",
                      productionOrder?.id,
                      error
                    );
                  }}
                  onChange={(modifiedLines) => {
                    const cloneSerials = [...(serials ?? [])];
                    for (const modifiedLine of modifiedLines) {
                      if (
                        modifiedLine.serial !== undefined &&
                        modifiedLine.serial.id !== undefined
                      ) {
                        cloneSerials.push(modifiedLine.serial);
                      }
                    }
                    setSerials(cloneSerials);
                    const clone: ProductionOrder = {
                      ...productionOrder!,
                      lines: [
                        ...productionOrder!.lines.map((l) => {
                          return { ...l };
                        }),
                      ],
                    };
                    onChange(clone);
                  }}
                />
              </TableCell>
              <TableCell style={{ flexGrow: 1 }}>Device ID</TableCell>
              <TableCell style={{ width: "10em" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {productionOrder === undefined ||
          productionOrder.lines === undefined ||
          productionOrder.lines.find(
            (l) => l.id !== undefined && l.product !== undefined
          ) === undefined ||
          productionOrder.lines.length === 0 ? (
            <TableBody>
              {productionOrder?.lines?.map((l) => (
                <TableRow key={l.id}>
                  <TableCell>
                    <Skeleton variant="rounded" animation="wave">
                      <Typography variant="body1">1</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" animation="wave">
                      <Typography variant="body1">BF13000123</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" animation="wave">
                      <Typography variant="body1">
                        some long product name - 0.0.0
                      </Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" animation="wave">
                      <TextField
                        size="small"
                        type="text"
                        label="Serial number"
                        disabled={true}
                        fullWidth
                        value={"123456789012"}
                      />
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" animation="wave">
                      <TextField
                        size="small"
                        type="text"
                        label="Device ID"
                        disabled={true}
                        fullWidth
                        value={"dock-123456789012"}
                      />
                    </Skeleton>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody data-cy="raw-ingredients">
              {productionOrder?.lines?.map((line, index) => {
                return (
                  <ProductionOrderLineEditor
                    key={index}
                    svc={svc}
                    line={line}
                    index={index}
                    inputRefs={inputRefs}
                    serials={new Serials(serials ?? [])}
                    stockQuants={(stockQuants ?? []).filter(
                      (sq) => id(sq.product_id) === line.product?.id
                    )}
                    onNfcStart={() => setNfcBusy(true)}
                    onNfcFinish={() => setNfcBusy(false)}
                    nfcBusy={nfcBusy}
                    onChange={async (newLine) => {
                      if (newLine === undefined || newLine.id === undefined) {
                        setErrorMessage("Unable to save PO line: no line id");
                        return;
                      }

                      const serial = newLine.serial;
                      if (serial === undefined) {
                        setErrorMessage("Unable to save PO line: no serial");
                        return;
                      } else if (!svc.isOdooProductSerial(serial)) {
                        setErrorMessage("Unable to save PO line: no serial id");
                        return;
                      }

                      if (
                        (serials ?? []).find((s) => s.id === serial.id) ===
                        undefined
                      ) {
                        (serials ?? []).push(serial);
                      }

                      try {
                        // Check that the serial number is not scrapped
                        if (
                          newLine.product?.inventory?.traceability?.tracking ===
                            Tracking.ByUniqueSerialNumber &&
                          svc.isOdooProductSerial(serial)
                        ) {
                          const currentLocations =
                            await svc.getCurrentLocations(serial);
                          if (isScrapped(currentLocations)) {
                            throw new Error(
                              `Serial ${serial.serial} is scrapped`
                            );
                          }
                        }

                        // Check that the PO is set to done
                        if (
                          newLine.product?.inventory?.traceability?.tracking ===
                            Tracking.ByUniqueSerialNumber &&
                          svc.isOdooProductSerial(serial)
                        ) {
                          const po = await svc.searchProductionOrderBySerialId(
                            serial.id
                          );
                          if (po && po.state !== "done") {
                            throw new Error(
                              `PO ${po.name} is not done: ${po.state}`
                            );
                          }
                        }

                        if (
                          Array.isArray(newLine.move_line_ids) &&
                          newLine.move_line_ids.length === 1 &&
                          typeof newLine.move_line_ids[0] === "number"
                        ) {
                          // The serial number of the stock.move.line is updated,
                          // persist the stock.move.line directly
                          const stockMoveLineId = newLine.move_line_ids[0];
                          const stockMoveLine = {
                            lot_id: newLine.serial?.id,
                            qty_done: newLine.product_qty,
                          } as StockMoveLine;
                          await svc.writeStockMoveLine(
                            stockMoveLineId,
                            stockMoveLine
                          );
                        } else {
                          // A stock.move.line should be added to the stock.move
                          const newStockMoveLine: StockMoveLine = {
                            company_id: newLine.company_id,
                            product_id: newLine.product?.id,
                            location_dest_id: VIRT_PRODUCTION,
                            location_id: WH_STOCK,
                            lot_id: serial?.id,
                            move_id: false,
                            owner_id: false,
                            package_id: false,
                            product_uom_id: 1 as any,
                            qty_done: line.product_qty,
                            result_package_id: false,
                          };

                          const clearAllCommand: RemoveAllRecordsCommand = [
                            5, 0, 0,
                          ];
                          const addNewCommand: AddNewRecordCommand<StockMoveLine> =
                            [0, 0, newStockMoveLine];

                          const updatedStockMove: OdooStockMove = {
                            move_line_ids: [clearAllCommand, addNewCommand],
                          } as OdooStockMove;

                          await svc.writeStockMove(line.id, updatedStockMove);
                        }

                        const clone: ProductionOrder = {
                          ...productionOrder,
                          lines: productionOrder.lines.map((l, i) =>
                            i === index ? newLine : l
                          ),
                        };

                        onChange(clone);
                      } catch (error) {
                        const e = error as Error;
                        console.error(
                          "Unable to update PO line",
                          JSON.stringify(line),
                          error
                        );
                        setErrorMessage(
                          `Unable to update PO line: ${e.message}`
                        );

                        const clone: ProductionOrder = {
                          ...productionOrder,
                        };
                        onChange(clone);
                      }
                    }}
                    onDeleteLine={(line) => {
                      productionOrder.lines.splice(index, 1);
                      const clone: ProductionOrder = {
                        ...productionOrder,
                        lines: productionOrder.lines.map((l) => l),
                      };
                      onChange(clone);
                    }}
                    onAddLine={(line) => {
                      const cloneLine: ProductionOrderLine = {
                        ...(line as ProductionOrderLine),
                        sequence:
                          line.sequence || getNextSequence(productionOrder),
                        bom_line_id: line.bom_line_id,
                        serial: {
                          ...line.serial,
                          udi: undefined,
                          deviceId: undefined,
                          serial: undefined,
                        },
                      };
                      productionOrder.lines.push(cloneLine);
                      const poClone: ProductionOrder = {
                        ...productionOrder,
                        lines: productionOrder.lines.map((l) => l),
                      };
                      onChange(poClone);
                    }}
                    canAddLine={(line) =>
                      line.product.internalReference === BF_NAKED_DOT
                    }
                    canDeleteLine={(line) =>
                      line.product.internalReference === BF_NAKED_DOT
                    }
                    canCopySerial={(line) => {
                      if (!svc.isOdooProductSerial(line.serial)) {
                        return false;
                      } else if (
                        productionOrder === undefined ||
                        (productionOrder.finishedSerial !== undefined &&
                          svc.isOdooProductSerial(
                            productionOrder.finishedSerial
                          ))
                      ) {
                        return false;
                      } else if (
                        !canCopySerialNumber(
                          line.product?.internalReference,
                          productionOrder.product?.internalReference
                        )
                      ) {
                        return false;
                      }
                      return true;
                    }}
                    allowedProducts={[line.product]}
                    onError={(error) => {
                      setErrorMessage("" + error);
                    }}
                    onCopySerial={async (serial) => {
                      console.log(
                        `Copying serial ${serial.serial} for product ${productionOrder.product.name}`
                      );
                      if (serial.serial === undefined) {
                        setErrorMessage("Serial is not defined");
                        return;
                      } else if (!svc.isOdooProductSerial(serial)) {
                        setErrorMessage("Serial is no an odoo serial");
                        return;
                      } else if (
                        productionOrder === undefined ||
                        productionOrder.finishedSerial === undefined ||
                        svc.isOdooProductSerial(productionOrder.finishedSerial)
                      ) {
                        console.error(
                          "Serial is already set, overwriting is not allowed"
                        );
                        setErrorMessage(
                          "Serial is already set, overwriting is not allowed"
                        );
                        return;
                      }

                      try {
                        let poSerial: ProductSerial;

                        const existingPoSerial = await svc.getSerial(
                          productionOrder.product,
                          serial.serial
                        );
                        if (existingPoSerial === undefined) {
                          console.log(`Creating new serial ${serial.serial}`);
                          poSerial = await svc.createProductSerial({
                            ...serial,
                            product: productionOrder.product,
                            id: undefined as any,
                          });
                        } else {
                          console.log(
                            `Serial ${serial.serial} already exists, ID: ${existingPoSerial.id}, reusing this serial`
                          );
                          const stockQuants = (
                            await svc.searchStockQuantsByLotId(
                              existingPoSerial.id
                            )
                          ).filter((sq) => sq.quantity !== 0);
                          if (stockQuants.length !== 0) {
                            const msg = `Serial has stock quants that are not zero: ${JSON.stringify(
                              stockQuants
                            )}`;
                            setErrorMessage(msg);
                            return;
                          }
                          poSerial = existingPoSerial;
                        }

                        await svc.writeProductionOrder(
                          productionOrder,
                          poSerial
                        );
                        const clone: ProductionOrder = {
                          ...productionOrder!,
                          finishedSerial: poSerial,
                          quantity:
                            productionOrder.quantity > 0
                              ? productionOrder.quantity
                              : 1,
                        };
                        onChange(clone);
                      } catch (error) {
                        console.error("Failed to copy serial", error);
                        setErrorMessage("Failed to copy serial");
                      }
                    }}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>

      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={3}>
          <TextField
            size="small"
            fullWidth
            label="Product"
            value={`${productionOrder?.quantity || ""} ${
              productionOrder?.product?.name || ""
            }`}
            placeholder="Quantity"
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            fullWidth
            label="Serial"
            value={
              productionOrder?.finishedSerial?.serial === undefined
                ? ""
                : productionOrder.finishedSerial.serial
            }
            placeholder="Serial"
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <NewSerialButton
            svc={svc}
            productionOrder={productionOrder}
            onError={(error) => {
              console.error("Failed to create new serial, please try to create it again", error);
              setErrorMessage(`Failed to create new serial, please try to create it again: ${error.message}`);
            }}
            onSerialCreated={(productionOrder, serial) => {
              const clone: ProductionOrder = {
                ...productionOrder,
                finishedSerial: serial,
              };
              onChange(clone);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DeviceIdField
            svc={svc}
            productInternalReference={
              productionOrder?.product.internalReference || ""
            }
            deviceId={productionOrder?.finishedSerial?.deviceId}
            onChange={(deviceId) => {
              const clonedSerial: ProductSerial = {
                ...productionOrder!.finishedSerial!,
                deviceId: deviceId,
              };
              const clone: ProductionOrder = {
                ...productionOrder!,
                finishedSerial: clonedSerial,
              };
              onChange(clone);
            }}
            onError={(error) => {
              console.error("Error when reading serial", error);
              setErrorMessage("Something went wrong with the serial" + error);
            }}
            onNfcStart={() => setNfcBusy(true)}
            onNfcFinish={() => setNfcBusy(false)}
            nfcBusy={nfcBusy}
          />
        </Grid>
        <Grid item xs={1}>
          {productionOrder?.product?.internalReference === BF_NAKED_DOCK && (
            <GetDockIdButton
              onDockId={(status) => {
                if (status !== undefined && status.DockID !== undefined) {
                  const clonedSerial: ProductSerial = {
                    ...productionOrder!.finishedSerial!,
                    deviceId: status.DockID,
                  };
                  const clone: ProductionOrder = {
                    ...productionOrder!,
                    finishedSerial: clonedSerial,
                  };
                  onChange(clone);
                }
              }}
              onError={(error) => {
                console.error("Failed to read Dock ID", error);
                setErrorMessage("Failed to read Dock ID");
              }}
            />
          )}

          {productionOrder?.product?.internalReference === BF_NAKED_DOT && (
            <SelectSensorDotButton
              disabled={
                // productionOrder.finishedSerial !== undefined &&
                // isNotBlank(productionOrder.finishedSerial.deviceId)
                false
              }
              onOK={(dotId) => {
                if (dotId !== undefined && dotId !== "") {
                  const clonedSerial: ProductSerial = {
                    ...productionOrder!.finishedSerial!,
                    deviceId: dotId,
                  };
                  const clone: ProductionOrder = {
                    ...productionOrder!,
                    finishedSerial: clonedSerial,
                  };
                  onChange(clone);
                }
              }}
            />
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={errorMessage !== undefined}
        autoHideDuration={60000}
        onClose={() => {
          setErrorMessage(undefined);
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage(undefined);
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProductionOrderEditor;
