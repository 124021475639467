import { useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { ProductSerial } from "../erp/manufacturing/Product";
import SerialSearchField, { SerialSource } from "../SerialSearchField";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import DockIcon from "../images/DockIcon";
import { BF_NAKED_DOCK } from "../Utils";
import { odooUrl } from "../erp/odoo/OdooUtils";
import { OdooModel } from "@byteflies/odoo-typescript";
import QrCodeIcon from "@mui/icons-material/QrCode";

interface SerialSelectionDialogProps {
  open: boolean;
  svc: OdooOperationsService;
  serialFilter: (serial: ProductSerial) => Promise<boolean>;
  onOK: (serials: { serial: ProductSerial; source: SerialSource }[]) => Promise<void>;
  onClose: () => void;
}
export function SerialSelectionDialog(props: SerialSelectionDialogProps) {
  const { svc, onOK, onClose, open, serialFilter } = props;
  const [error, setError] = useState<Error>();
  const [serials, setSerials] = useState<
    { serial: ProductSerial; source: SerialSource }[]
  >([]);
  const [busy, setBusy] = useState<boolean>(false);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      data-cy={`serials-selection-dialog`}
      scroll="paper"
      fullWidth={true}
    >
      <DialogTitle>Scan serial numbers</DialogTitle>
      <DialogContent>
        <SerialSearchField
          svc={svc}
          onError={(e) => {
            setError(e);
          }}
          onSerialsSelected={async (newSerials) => {
            if (newSerials.length === 0) {
              setError(Error("No serials found"));
              return;
            }
            setError(undefined);
            const serialsClone = [...serials];
            for (const newSerial of newSerials) {
              try {
                if (
                  !serialsClone
                    .map((s) => s.serial.id)
                    .includes(newSerial.serial.id) &&
                  (await serialFilter(newSerial.serial))
                ) {
                  serialsClone.push(newSerial);
                }
              } catch (e) {
                setError(e as Error);
                return;
              }
            }
            setSerials(serialsClone);
          }}
          onClear={() => {
            setSerials([]);
          }}
          fullWidth
        />

        <DialogContentText>Serials</DialogContentText>
        <List>
          {serials.map((serial) => (
            <ListItem key={serial.serial.id}>
              <ListItemAvatar>
                <Avatar>
                  {serial.serial.product?.internalReference ===
                  BF_NAKED_DOCK ? (
                    <DockIcon />
                  ) : (
                    <QrCodeIcon />
                  )}
                </Avatar>
              </ListItemAvatar>

              <ListItemText>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    odooUrl("stock.lot" as OdooModel, serial.serial.id || -1) ||
                    ""
                  }
                >
                  {serial.serial.serial}
                </a>
              </ListItemText>
            </ListItem>
          ))}
        </List>

        {error !== undefined && (
          <Alert severity="warning">{error.message}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="cancel"
          onClick={() => {
            setSerials([]);
            onClose();
          }}
          color="secondary"
          disabled={busy || serials.length === 0}
        >
          Cancel
        </Button>

        <Button
          data-cy="ok"
          variant="contained"
          color="primary"
          onClick={async () => {
            setBusy(true);
            try {
              await onOK(serials);
            } catch (error) {
              console.error(error);
              setError(error as Error);
            }
            setSerials([]);
            setBusy(false);
          }}
          disabled={busy || serials.length === 0}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
