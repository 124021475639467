import { useEffect, useState } from "react";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";

import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { QualityCheck } from "../erp/manufacturing/Product";
import { query } from "../FieldTestApi";
import {
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import { Typography } from "@mui/material";
import RedButton from "../actions/RedButton";
import GreenButton from "../actions/GreenButton";
import { DateTime } from "luxon";

interface QualityCheckBluetoothStrengthProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  deviceId?: string;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckBluetoothStrength(
  props: QualityCheckBluetoothStrengthProps
) {
  const { svc, qualityCheck, deviceId, onSuccess, onFail } = props;

  const [lastSeen, setLastSeen] = useState<DateTime>();
  const [lastSeenOK, setLastSeenOK] = useState<boolean>();

  useEffect(() => {
    if (!deviceId) {
      console.error("DeviceId is undefined, unable to check signal");
    }

    setLastSeen(undefined);
    setLastSeenOK(undefined);

    const timer = setIntervalAsync(
      async (deviceId: string) => {
        try {
          const data = await query(deviceId);
          if (data !== undefined) {
            const lastSeenDate = DateTime.fromISO(data.LastSeen);
            setLastSeen(lastSeenDate);
            setLastSeenOK(lastSeenDate > (DateTime.now().minus({weeks: 1})) ? true : false);
          } else {
            console.warn("Something went wrong getting signal data");
          }
        } catch (error) {
          console.error("Failed to get Sensor Dot BLE data", error);
        }
      },
      1000,
      deviceId
    );
    return () => {
      clearIntervalAsync(timer);
    };
  }, [deviceId]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
              {qualityCheckCode(qualityCheck)}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="center">
          <Typography
            variant="h6"
            align="center"
            color={
              lastSeenOK === true
                ? "success.main"
                : lastSeenOK === false
                ? "error.main"
                : "warning.main"
            }
          >
            Last seen:{" "}
            {lastSeen
              ? lastSeen?.toLocaleString(DateTime.DATETIME_SHORT)
              : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <RedButton
            data-cy="fail"
            fullWidth
            label="Fail"
            disabled={lastSeenOK === true}
            onClick={async () => {
              try {
                await svc.saveQualityCheck(qualityCheck, false);
                qualityCheck.state = "fail";
                onFail();
              } catch (error) {
                console.error(
                  "Failed to save quality check",
                  error,
                  qualityCheck
                );
                props.onError(error);
              }
            }}
            onError={(error) => {
              props.onError(error);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <GreenButton
            qualityCheckCode={qualityCheckCode(qualityCheck)}
            label="Pass"
            fullWidth
            disabled={lastSeenOK === undefined || lastSeenOK === false}
            onClick={async () => {
              try {
                await svc.saveQualityCheck(qualityCheck, true);
                qualityCheck.state = "pass";
                onSuccess();
              } catch (error) {
                console.error(
                  "Failed to save quality check",
                  error,
                  qualityCheck
                );
                props.onError(error);
              }
            }}
            onError={(error) => {
              props.onError(error);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckBluetoothStrength;
