export const WI_SNIPEIT = "QMS/WI-293";
export const WI_WRITE_NFC_TAG = "QMS/WI-294";
export const WI_PRINT_LABEL = "QMS/WI-295";
export const WI_BUILD_DOT = "QMS/WI-301";
export const WI_DOT_SIGNAL_QUALITY = "QMS/WI-302";
export const WI_CALIBRATE_DOT = "QMS/WI-303";
export const WI_24_HR_TEST = "QMS/WI-305";
export const WI_24_HR_TEST_SERVICING = "QMS/WI-690";
export const WI_BUILD_DOCK = "QMS/WI-307";
export const WI_DOCK_TEST = "QMS/WI-308";
export const WI_BUILD_NAKED_2SNAP_CRADLE = "QMS/WI-310";
export const WI_TEST_2SNAP_CRADLE = "QMS/WI-311";
export const WI_BUILD_NAKED_4WIRE_CRADLE = "QMS/WI-313";
export const WI_TEST_4WIRE_CRADLE = "QMS/WI-314";
export const WI_TEST_4WIRE_CRADLE_WITH_FILTER = "QMS/WI-315";
export const WI_BUILD_NAKED_4SNAP_CRADLE = "QMS/WI-318";
export const WI_TEST_4SNAP_CRADLE = "QMS/WI-319";
export const WI_BUILD_NAKED_3SNAP_CRADLE_LEFT = "QMS/WI-322";
export const WI_TEST_3SNAP_CRADLE_LEFT = "QMS/WI-324";
export const WI_BUILD_NAKED_3SNAP_CRADLE_RIGHT = "QMS/WI-326";
export const WI_TEST_3SNAP_CRADLE_RIGHT = "QMS/WI-328";
export const WI_BUILD_NAKED_MOTION_CRADLE = "QMS/WI-329";
export const WI_TEST_MOTION_CRADLE = "QMS/WI-330";
export const WI_PACKAGE_DOT = "QMS/WI-331";
export const WI_PACKAGE_2SNAP_CRADLE = "QMS/WI-332";
export const WI_PACKAGE_3SNAP_CRADLE_LEFT = "QMS/WI-333";
export const WI_PACKAGE_3SNAP_CRADLE_RIGHT = "QMS/WI-334";
export const WI_PACKAGE_4SNAP_CRADLE = "QMS/WI-335";
export const WI_PACKAGE_4WIRE_CRADLE = "QMS/WI-336";
export const WI_PACKAGE_BF_KIT = "QMS/WI-337";
export const WI_PACKAGE_COVID_BOX = "QMS/WI-339";
export const WI_PACKAGE_EEG_ADHESIVE = "QMS/WI-340";
export const WI_PACKAGE_ECG_ADHESIVE = "QMS/WI-341";
export const WI_PACKAGE_DOUBLE_SIDED_ADHESIVE = "QMS/WI-342";
export const WI_PACKAGE_EPICARE_ABSCENCE_BOX = "QMS/WI-344";
export const WI_CONFIGURE_EEG_DOTS = "QMS/WI-346";
export const WI_PACKAGE_CARDIO_BOX = "QMS/WI-347";
export const WI_CONFIGURE_ECG_DOTS = "QMS/WI-349";
export const WI_PACKAGE_EPICARE_FOCAL_LEFT_BOX = "QMS/WI-351";
export const WI_PACKAGE_EPICARE_FOCAL_RIGHT_BOX = "QMS/WI-353";
export const WI_CONFIGURE_ECG_DOTS_EPICARE = "QMS/WI-355";
export const WI_DOCK_VISUAL_INSPECTION = "QMS/WI-438";
export const WI_DOCK_INTERFACE_TEST = "QMS/WI-439";
export const WI_WIFI_TEST = "QMS/WI-440";
export const WI_DOCK_FIRMWARE_UPDATE = "QMS/WI-441";
export const WI_DOCK_CHECK_RECORDINGS = "QMS/WI-442";
export const WI_FACTORY_RESET = "QMS/WI-443";
export const WI_DOCK_CLEANING = "QMS/WI-445";
export const WI_PRINT_LABEL_DOCK_SERVICING = "QMS/WI-444";
export const WI_CLEAN_XCARE_BOX = "QMS/WI-397";
export const WI_INSPECT_LABEL = "QMS/WI-408";
export const WI_VISUAL_INSPECTION = "QMS/WI-451";
export const WI_DOCK_BAT_TEST = "QMS/WI-454";
export const WI_BUILD_NAKED_ECG_3SNAP_CRADLE = "QMS/WI-458";
export const WI_TEST_CRADLE_RESISTANCES = "QMS/WI-459";
export const WI_PACKAGE_ADHESIVE = "QMS/WI-460";
export const WI_PACKAGE_CARDIO_MULTI_LEAD_LINEAR_BOX = "QMS/WI-461";
export const WI_PACKAGE_ECG_3SNAP_CRADLE = "QMS/WI-462";
export const WI_CONFIGURE_ECG_DOTS_DUAL_CHANNEL = "QMS/WI-463";
export const WI_HARDWARE_VERSION_IDENTIFICATION = "QMS/WI-469";
export const WI_DOT_VISUAL_INSPECTION = "QMS/WI-470";
export const WI_DOT_EXTERNAL_CLEANING = "QMS/WI-472";
export const WI_WRITE_CRADLE_ID_CHIP = "QMS/WI-479";
export const WI_CONFIGURE_EPICARE_FOCAL_BOX_DOTS = "QMS/WI-494";
export const WI_SENSOR_DOT_HW_TEST = "QMS/WI-495";
export const WI_SENSOR_DOT_UPLOAD_RECORDINGS = "QMS/WI-496";
export const WI_DOCK_AUTO_VERSION_CHECK = "QMS/WI-498";
export const WI_LINE_CLEARANCE_END = "QMS/WI-506";
export const WI_LINE_CLEARANCE_START = "QMS/WI-501";
export const WI_CRADLE_VISUAL_INSPECTION = "QMS/WI-517";
export const WI_4WIRESNAP_TEST_WITH_DEVICE = "QMS/WI-316";
export const WI_CLEAN_CRADLE_CONNECTORS = "QMS/WI-520";
export const WI_BUILD_24H_HOLTER = "QMS/WI-524";
export const WI_BUILD_24H_STARTER_KIT = "QMS/WI-525";
export const WI_DOT_VISUAL_INSPECTION_POGOPINS = "QMS/WI-531";
export const WI_DOT_VISUAL_INSPECTION_CRACKS = "QMS/WI-530";
export const WI_DOT_VISUAL_INSPECTION_WELD_SEAM = "QMS/WI-529";
export const WI_DOCK_VISUAL_INSPECTION_INTERFACE = "QMS/WI-540";
export const WI_DOCK_VISUAL_INSPECTION_SCREWS = "QMS/WI-535";
export const WI_DOCK_VISUAL_INSPECTION_CASING = "QMS/WI-534";
export const WI_DOCK_VISUAL_INSPECTION_BUMPER_FEET = "QMS/WI-533";
export const WI_CRADLE_VISUAL_INSPECTION_CONNECTORS = "QMS/WI-539";
export const WI_CRADLE_VISUAL_INSPECTION_POGOPADS = "QMS/WI-538";
export const WI_CRADLE_VISUAL_INSPECTION_CRACKS = "QMS/WI-537";
export const WI_CRADLE_VISUAL_INSPECTION_WELD_SEAM = "QMS/WI-536";
export const WI_BLE_TEST = "QMS/WI-555";
export const WI_DOCK_PROVISIONING = "QMS/WI-560";
export const WI_CONNECT_DOCK_MOBILE_WIFI = "QMS/WI-642";
export const WI_PAIR_AD_MEDICAL_UA656BLE = "QMS/WI-629";
export const WI_BUILD_VITAL_SIGNS_VS1 = "QMS/WI-630";
export const WI_BUILD_VITAL_SIGNS_VS2 = "QMS/WI-631";
export const WI_BUILD_VITAL_SIGNS_VS3 = "QMS/WI-632";
export const WI_BUILD_VITAL_SIGNS_VS4 = "QMS/WI-633";
export const WI_BATTERY_HEALTH_CHECK = "QMS/WI-664";
export const WI_THIRD_PARTY_DEVICE_VISUAL_INSPECTION = "QMS/WI-678";
export const WI_THIRD_PARTY_READABILITY_LABEL = "QMS/WI-679";
export const WI_THIRD_PARTY_CHECK_ACCESSORIES = "QMS/WI-682";
export const WI_THIRD_PARTY_CHECK_BATTERY = "QMS/WI-680";
export const WI_THIRD_PARTY_DEVICE_CONFIGURE = "QMS/WI-685";
export const WI_THIRD_PARTY_DEVICE_PROPER_FUNCTIONING = "QMS/WI-683";
export const WI_THIRD_PARTY_DEVICE_FACTORY_RESET = "QMS/WI-684";
export const WI_THIRD_PARTY_DEVICE_CLEANING = "QMS/WI-681";
export const WI_THIRD_PARTY_PROTECTIVE_PACKAGING = "QMS/WI-689";
