import axios, { AxiosResponse } from "axios";
import {
  ReadProduction,
  ReadProductionLot,
  ProductionOrder,
  GenericArgs,
  GetProduct,
  ReadStockMove,
  StockProductionLot,
  QualityCheck,
  OdooMethod,
  OdooError,
  ActionValidateResponse,
  CallParams,
  OdooModel,
  AddNewRecordCommand,
} from "@byteflies/odoo-typescript";
import { RouteCardReadProductionLot } from "../manufacturing/OdooOperationsService";

axios.defaults.adapter = require("axios/lib/adapters/http");

const HEADER_X_ODOO_SESSION_ID = "x-odoo-session-id";

export type OdooFieldName = string;

export type OdooOperator =
  | "="
  | "!="
  | ">"
  | ">="
  | "<"
  | "<="
  | "like"
  | "not like"
  | "ilike"
  | "not ilike"
  | "in"
  | "not in";

export type OdooOperatorValue =
  | string
  | number
  | object
  | boolean
  | any[]
  | undefined;

export type OdooDomainPart =
  | "&"
  | "|"
  | [OdooFieldName, OdooOperator, OdooOperatorValue];

export interface JsonRpcRequestBody<P> {
  jsonrpc: "2.0";
  method: OdooMethod;
  params: P;
}

export interface SearchResponse<T> {
  id: number;
  jsonrpc: "2.0";
  result: {
    length: number;
    records: T[];
  };
  error: undefined | OdooError;
}
export interface UserContext {
  lang: string;
  tz: string;
  uid: number;
}
export interface GetDatabaseResult {
  id: number;
  jsonrpc: "2.0";
  result: {
    databases: Database[];
  };
  error: undefined | OdooError;
}
export interface Database {
  db_id: number;
  db_name: string; // byteflies-kitchen-main-8701667
  version: string; // 14.0+e
  company_name: string; // Byteflies
  uuid: string;
  parent_uuid: false;
  url: string; // https://odoo.kitchen.byteflies.com
  is_registered_user: true;
  is_trial: false;
  is_paid: true;
  auth_url: string; // https://odoo.kitchen.byteflies.com/web
}

export type ServerVersionInfo = [14 | 15, number, number, "final", 0, "e"];

export interface LoginInfo {
  uid: number;
  is_system: boolean;
  is_admin: boolean;
  user_context: UserContext;
  db: string;
  name: string;
  username: string;
  company_id: number;
  "web.base.url": string | undefined;
  server_version: "14.0+e" | string;
  server_version_info: ServerVersionInfo;
}

export interface LoginResult {
  jsonrpc: "2.0";
  result: LoginInfo;
  error: undefined | Error;
}
export interface CallButtonResult {
  id?: number;
  name: string; //Consumption Warning
  view_mode: "form";
  res_model: OdooModel;
  res_id?: number;
  view_id: number;
  type: "ir.actions.act_window";
  context?: KwargsContext;
  target: "new";
  views: [number, string][];
}
export interface KwargsContext {
  lang: string;
  tz: string;
  uid: number;
  allowed_company_ids: number[];
  search_default_consumable: number;
  default_type: string;
  active_id?: number;
  active_ids?: number[];
  active_model?: OdooModel;
  default_scrap_id?: number;
  default_unbuild_id?: number;
  default_quantity?: number;
  default_location_id?: number;
  default_product_id?: number;
  default_product_uom_name?: string;
  button_mark_done_production_ids?: number[];
  default_mrp_production_ids?: number[];
  default_mrp_consumption_warning_line_ids?: AddNewRecordCommand<any>[];
  default_check_id?: number;
  check_failed_qcs?: boolean;
  routecard: boolean;
  skip_immediate?: boolean;
  skip_backorder?: boolean;
}

export interface Session {
  cookies: string[];
  user: LoginInfo;
}
export function getCookies(resp: AxiosResponse<any>) {
  if (
    resp.headers !== undefined &&
    resp.headers[HEADER_X_ODOO_SESSION_ID] !== undefined
  ) {
    const xOdooSessionId = resp.headers[HEADER_X_ODOO_SESSION_ID];
    if (typeof xOdooSessionId === "string") {
      return [xOdooSessionId];
    } else if (Array.isArray(xOdooSessionId) && xOdooSessionId.length > 0) {
      return xOdooSessionId as string[];
    }
  }

  const cookies: string[] =
    resp.headers["set-cookie"] ||
    resp.headers["session-id-cookie-proxied"] ||
    [];

  return typeof cookies === "string" ? [cookies] : cookies;
}

function isNodeJS() {
  return (
    typeof process !== "undefined" &&
    process !== undefined &&
    process.versions &&
    process.versions.node
  );
}
export function sessionHeaders(session: Session) {
  const headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  headers[HEADER_X_ODOO_SESSION_ID] = "X";

  if (
    session.user !== undefined &&
    session.user["web.base.url"] !== undefined
  ) {
    headers["x-odoo-web-base-url"] = session.user["web.base.url"];
  }
  if (
    session !== undefined &&
    session.cookies !== undefined &&
    session.cookies.length > 0 &&
    isNodeJS()
  ) {
    headers["Cookie"] = session.cookies.join("; ");
  }
  if (
    session !== undefined &&
    session.cookies !== undefined &&
    Array.isArray(session.cookies) &&
    session.cookies.length > 0
  ) {
    headers[HEADER_X_ODOO_SESSION_ID] = session.cookies[0];
  }
  if (
    session !== undefined &&
    session.cookies !== undefined &&
    typeof session.cookies === "string"
  ) {
    headers[HEADER_X_ODOO_SESSION_ID] = session.cookies as string;
  }

  return headers;
}
export default class OdooClient {
  constructor(private host: string, private dbname?: string, private odooUrl?: string) {}

  public async login(login: string, password: string): Promise<Session> {
    console.log("Logging into Odoo");
    const resp = await axios.post<LoginResult>(
      "/web/session/authenticate",
      {
        params: {
          db: this.dbname,
          login: login,
          password: password,
        },
      },
      {
        headers: sessionHeaders({
          cookies: [],
          user: {
            db: this.dbname,
            "web.base.url": this.odooUrl,
          } as LoginInfo,
        }),
        baseURL: this.host,
      }
    );
    if (resp.status !== 200) {
      throw new Error(resp.statusText);
    }
    if (resp.data.error !== undefined) {
      console.info("/web/session/authenticate", login);
      console.error(JSON.stringify(resp.data.error));
      throw new Error(resp.data.error.message);
    }

    const cookies = getCookies(resp);
    console.info("response headers", JSON.stringify(resp.headers));
    console.info("response cookies", JSON.stringify(cookies));
    const session: Session = {
      cookies: cookies,
      user: resp.data.result,
    };
    return session;
  }

  rewriteCallParams(session: Session, params: CallParams) {
    const majorVersion =
      session.user?.server_version_info !== undefined &&
      Array.isArray(session.user.server_version_info)
        ? session.user?.server_version_info[0]
        : -1;
    if (majorVersion === 14) {
      if (params.model === "stock.lot") {
        params.model = "stock.production.lot";
      }
    }
    return params;
  }

  async call<T>(session: Session, url: string, params: CallParams) {
    const body = {
      jsonrpc: "2.0",
      method: "call",
      params: this.rewriteCallParams(session, params),
    };

    const requestHeaders = sessionHeaders(session);
    const resp = await axios.post<ActionValidateResponse>(url, body, {
      timeout: 60000,
      headers: requestHeaders,
      baseURL: this.host,
      // withCredentials: true,
    });
    if (resp.status !== 200) {
      throw new Error(resp.statusText);
    }
    if (resp.data.error !== undefined) {
      console.info(url, "POST", JSON.stringify(body));
      console.error(JSON.stringify(resp.data.error));
      throw new Error(
        resp.data.error.message + ": " + resp.data.error.data.message
      );
    }
    const r = resp.data.result as unknown as T;
    return r;
  }

  async searchRead<T>(
    session: Session,
    domain: OdooDomainPart[],
    fields: string[],
    groupby: string[] | undefined,
    limit: number,
    sort: string | undefined,
    model: OdooModel
  ) {
    if (session === undefined || session.user === undefined) {
      throw new Error("Not logged in");
    }
    const body = {
      jsonrpc: "2.0",
      method: "call",
      params: this.rewriteCallParams(session, {
        context: this.sessionToContext(session),
        domain: domain,
        fields: fields,
        groupby: groupby,
        limit: limit,
        model: model,
        sort: sort,
      } as any),
    };

    const resp = await axios.post<SearchResponse<T>>(
      "/web/dataset/search_read",
      body,
      {
        headers: sessionHeaders(session),
        baseURL: this.host,
      }
    );
    if (resp.status !== 200) {
      throw new Error(resp.statusText);
    }
    if (resp.data.error !== undefined) {
      console.info(JSON.stringify(domain), model);
      console.error(JSON.stringify(resp.data.error));
      throw new Error(resp.data.error.message);
    }

    return resp.data.result;
  }

  public async read<T>(session: Session, args: any[], model: OdooModel) {
    if (session === undefined || session.user === undefined) {
      throw new Error("Not logged in");
    }
    const body = {
      jsonrpc: "2.0",
      method: "call",
      params: this.rewriteCallParams(session, {
        kwargs: {
          context: this.sessionToContext(session),
        },
        args: args,
        model: model,
        method: "read",
      }),
    };

    const resp = await axios.post<SearchResponse<T>>(
      "/web/dataset/call_kw/" + model + "/read",
      body,
      {
        headers: sessionHeaders(session),
        baseURL: this.host,
      }
    );
    if (resp.status !== 200) {
      throw new Error(resp.statusText);
    }
    if (resp.data.error !== undefined) {
      console.info(JSON.stringify(args), model);
      console.error(JSON.stringify(resp.data.error));
      throw new Error(resp.data.error.message);
    }

    return resp.data.result as unknown as T;
  }

  public async unlink(session: Session, ids: number[], model: OdooModel) {
    if (session === undefined || session.user === undefined) {
      throw new Error("Not logged in");
    }
    const params = {
      args: [ids],
      kwargs: {
        context: this.sessionToContext(session),
      },
      model: model,
      method: "unlink",
    };
    return await this.call<void>(
      session,
      "/web/dataset/call_kw/" + model + "/unlink",
      params
    );
  }

  public async callButtonIds(
    session: Session,
    model: OdooModel,
    method: string,
    ids: number[],
    context?: Partial<KwargsContext>,
  ) {
    const params = {
      kwargs: {
        context: { ...this.sessionToContext(session), ...context },
      },
      args: [ids],
      method: method,
      model: model,
    };

    const result = await this.call<boolean | CallButtonResult>(
      session,
      "/web/dataset/call_button",
      params
    );
    return result;
  }

  private async actionConfirm(
    session: Session,
    ids: number[],
    model: OdooModel
  ) {
    const params = {
      kwargs: {
        context: this.sessionToContext(session),
      },
      args: ids,
      method: "action_confirm",
      model: model,
    };
    return this.call<boolean>(
      session,
      "/web/dataset/call_kw/" + model + "/action_confirm",
      params
    );
  }

  private async nameSearch(
    session: Session,
    ids: number[],
    model: OdooModel
  ) {
    const params = {
      kwargs: {
        context: this.sessionToContext(session),
        args: [['id', 'in', ids]],
      },
      method: "name_search",
      model: model,
      args: [],
    };
    return this.call<[number, string][]>(
      session,
      "/web/dataset/call_kw/" + model + "/name_search",
      params
    );
  }

  private qualityCheckFields = [
    "product_id",
    "name",
    "title",
    "note",
    "team_id",
    "picking_id",
    "point_id",
    "test_type",
    "test_type_id",
    "measure_success",
    "company_id",
    "measure",
    "norm_unit",
    "display_name",
    "quality_state",
    "norm_unit",
    "tolerance_min",
    "tolerance_max",
    "lot_id",
  ];

  public async readQualityChecks(session: Session, ids: number[]) {
    const results = await this.read<QualityCheck[]>(
      session,
      [ids, this.qualityCheckFields],
      "quality.check"
    );
    if (results === undefined) {
      return [];
    } else {
      return results;
    }
  }

  public mrp_production_fields = [
    "confirm_cancel",
    "show_lock",
    "state",
    "reservation_state",
    "date_planned_finished",
    "is_locked",
    "qty_produced",
    "unreserve_visible",
    "reserve_visible",
    "consumption",
    "is_planned",
    "purchase_order_count",
    "mrp_production_child_count",
    "mrp_production_source_count",
    "mrp_production_backorder_count",
    "scrap_count",
    "delivery_count",
    "priority",
    "name",
    "id",
    //"use_create_components_lots",
    "show_lot_ids",
    //"allowed_product_ids",
    "product_tracking",
    //"show_valuation",
    "product_id",
    "product_tmpl_id",
    "product_description_variants",
    "qty_producing",
    "product_qty",
    "product_uom_category_id",
    "product_uom_id",
    "lot_producing_id",
    "bom_id",
    "date_planned_start",
    //"delay_alert_date",
    //"json_popover",
    "user_id",
    "company_id",
    //"show_final_lots",
    "production_location_id",
    "move_finished_ids",
    "move_raw_ids",
    "workorder_ids",
    //"move_byproduct_ids",
    "picking_type_id",
    "location_src_id",
    "location_dest_id",
    "origin",
    "date_deadline",
    //"message_follower_ids",
    //"activity_ids",
    //"message_ids",
    "display_name",
    "check_ids",
    "quality_alert_count",
    "quality_check_fail",
    "quality_check_todo",
    "quality_alert_ids",
    "unbuild_ids",
    "unbuild_count",
  ];

  public async readProductionOrder(session: Session, id: number) {
    const results = await this.read<ReadProduction[]>(
      session,
      [[id], this.mrp_production_fields],
      "mrp.production"
    );
    if (results === undefined) {
      return undefined;
    } else if (results.length === 1) {
      return results[0] as ReadProduction;
    }
    return undefined;
  }

  public async readStockProductionLot(session: Session, id: number) {
    const results = await this.read<RouteCardReadProductionLot[]>(
      session,
      [[id], this.stockProductionLotFields],
      "stock.lot" as OdooModel
    );
    if (results === undefined) {
      return undefined;
    } else if (results.length === 1) {
      return results[0] as RouteCardReadProductionLot;
    }
    return undefined;
  }

  private stock_move_fields = [
    "product_id",
    "move_line_ids",
    "company_id",
    "product_uom_category_id",
    "name",
    "allowed_operation_ids",
    "unit_factor",
    "date_deadline",
    "date",
    "additional",
    "picking_type_id",
    "has_tracking",
    "operation_id",
    "is_done",
    "bom_line_id",
    "sequence",
    "location_id",
    "warehouse_id",
    "is_locked",
    //"has_move_lines",
    "location_dest_id",
    "state",
    //"should_consume_qty",
    "product_uom_qty",
    "product_type",
    "product_qty",
    //"reserved_availability",
    //"forecast_expected_date",
    //"forecast_availability",
    //"is_quantity_done_editable",
    "quantity_done",
    "product_uom",
    //"show_details_visible",
    "lot_ids",
  ];

  public async readStockMoves(session: Session, stockMoveIds: number[]) {
    if (stockMoveIds === undefined || stockMoveIds.length === 0) {
      return [];
    }
    const results = await this.read<ReadStockMove[]>(
      session,
      [stockMoveIds, this.stock_move_fields],
      "stock.move"
    );
    return results;
  }

  private productFields = [
    "id",
    "product_variant_count",
    "currency_id",
    "name",
    "activity_state",
    "default_code",
    //"lst_price",
    "qty_available",
    "uom_id",
    "type",
    "company_id",
    "tracking",
    "sale_ok",
    "purchase_ok",
    "description",
    "display_name",
    "route_ids",
  ];

  public async readProduct(session: Session, product_id: number) {
    const products = await this.read<GetProduct[]>(
      session,
      [[product_id], this.productFields],
      "product.product" as OdooModel
    );
    if (products === undefined || products.length === 0) {
      throw new Error(`Unable to read Product ${product_id}`);
    }
    return products[0];
  }

  public async readProducts(session: Session, product_ids: number[]) {
    return await this.read<GetProduct[]>(
      session,
      [product_ids, this.productFields],
      "product.product" as OdooModel
    );
  }

  private stockProductionLotFields = [
    "name",
    "ref",
    "product_id",
    "create_date",
    "company_id",
  ];

  public async readSerials(session: Session, lot_ids: number[]) {
    if (lot_ids === undefined || lot_ids.length === 0) {
      return [];
    }
    const lots = await this.read<RouteCardReadProductionLot[]>(
      session,
      [lot_ids, this.stockProductionLotFields],
      "stock.lot" as OdooModel
    );
    if (lots === undefined || lots.length === 0) {
      return [];
    }
    return lots;
  }

  public async searchStockProductionLotByProductAndName(
    session: Session,
    productId: number,
    name: string
  ) {
    if (productId === undefined || name === undefined) {
      throw new Error();
    }
    const lots = await this.searchRead<StockProductionLot>(
      session,
      ["&", ["product_id", "=", productId], ["name", "=", name]],
      ["name", "ref", "product_id", "create_date", "company_id"],
      undefined,
      80,
      undefined,
      "stock.lot" as OdooModel
    );
    if (lots.length === 0) {
      return [];
    }
    return lots.records as unknown as ReadProductionLot[];
  }

  public sessionToContext(session: Session): KwargsContext {
    return {
      lang: session.user.user_context.lang,
      tz: session.user.user_context.tz,
      uid: session.user.uid,
      allowed_company_ids: [session.user.company_id],
      search_default_consumable: 1,
      default_type: "product",
      routecard: true,
    };
  }

  public async writeProductionOrder(
    session: Session,
    workOrderId: number,
    workOrder: ProductionOrder
  ) {
    return await this.write(session, workOrderId, workOrder, "mrp.production");
  }

  public async confirmProductionOrder(session: Session, workOrderId: number) {
    return await this.actionConfirm(session, [workOrderId], "mrp.production");
  }

  public async getProductNames(session: Session, productIds: number[]) {
    return await this.nameSearch(session, productIds, "product.product");
  }

  public async create(session: Session, args: GenericArgs[], model: OdooModel) {
    const params = {
      args: args,
      model: model,
      method: "create",
      kwargs: {
        context: this.sessionToContext(session),
      },
    };

    return this.call<number>(
      session,
      "/web/dataset/call_kw/" + model + "/create",
      params
    );
  }

  public async createWithKwargs(
    session: Session,
    args: GenericArgs[],
    model: OdooModel,
    kwargs: any
  ) {
    const params = {
      args: args,
      model: model,
      method: "create",
      kwargs: kwargs,
    };

    return this.call<number>(
      session,
      "/web/dataset/call_kw/" + model + "/create",
      params
    );
  }

  async write(
    session: Session,
    id: number,
    arg: GenericArgs,
    model: OdooModel
  ) {
    const params = {
      args: [[id], arg],
      model: model,
      method: "write",
      kwargs: {
        context: this.sessionToContext(session),
      },
    };

    return this.call<number>(
      session,
      "/web/dataset/call_kw/" + model + "/write",
      params
    );
  }

  public async postMessage(
    session: Session,
    ids: number[],
    model: OdooModel,
    body: string
  ) {
    const params = {
      kwargs: {
        context: this.sessionToContext(session),
        body,
      },
      args: ids,
      method: "message_post",
      model: model,
    };
    return this.call<boolean>(
      session,
      "/web/dataset/call_kw/" + model + "/message_post",
      params
    );
  }

  public async run(
    session: Session,
    action_id: number,
    active_model: OdooModel,
    active_ids: number[]
  ) {
    const ctx = this.sessionToContext(session);
    const params: CallParams = {
      context: {
        ...ctx,
        active_id: active_ids[0],
        active_ids: active_ids,
        active_model: active_model,
      },
      action_id: action_id,
    } as any as CallParams;

    return await this.call<boolean | CallButtonResult>(
      session,
      "/web/action/run",
      params
    );
  }
}
