import { useCallback, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
import IOdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import { id, name, odooUrl, WH_QUARANTAINE } from "./erp/odoo/OdooUtils";
import { ProductSerial, QualityCheck } from "./erp/manufacturing/Product";
import { IdWithName, StockPicking } from "@byteflies/odoo-typescript";
import QualityCheckPassFail from "./quality_checks/QualityCheckPassFail";
import { isNotBlank } from "./StringUtils";
import {
  Alert,
  Breadcrumbs,
  Button,
  Grid,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { SkipNext, SkipPrevious } from "@mui/icons-material";
import GreenButton from "./actions/GreenButton";
import {
  getProductVersion,
  isScrappableQualityCheck,
  qualityCheckCode,
  qualityCheckCodeWithoutProject,
  sortQualityChecks,
} from "./Utils";
import {
  WI_DOCK_TEST,
  WI_PRINT_LABEL_DOCK_SERVICING,
  WI_HARDWARE_VERSION_IDENTIFICATION,
  WI_DOT_SIGNAL_QUALITY,
  WI_TEST_CRADLE_RESISTANCES,
} from "./WorkInstructions";
import QualityCheckDockTest from "./quality_checks/QualityCheckDockTest";
import QualityCheckPrintLabel from "./quality_checks/QualityCheckPrintLabel";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import QualityCheckHardwareVersion from "./quality_checks/QualityCheckHardwareVersion";
import QualityCheckWiTestSensorDot from "./quality_checks/QualityCheckTestSensorDot";
import { QualityAlertButton } from "./actions/QualityAlertButton";
import { QualityCheckTestCradle } from "./quality_checks/QualityCheckTestCradle";
import { ScrapButton } from "./actions/ScrapButton";

const allChecksPassed = (qualityChecks: QualityCheck[]) => {
  if (qualityChecks === undefined || qualityChecks.length === 0) {
    return true;
  }

  const notPassed = qualityChecks.filter((qc) => qc.state !== "pass");
  return notPassed.length === 0;
};

interface ParamTypes {
  spid: string | undefined;
  mlid: string | undefined;
  qcIdx: string | undefined;
}

interface StockPickingProps {
  basePath: string;
  svc: IOdooOperationsService;
}

function StockPickingEditor(props: StockPickingProps) {
  const { spid, mlid, qcIdx } = useParams<ParamTypes>();
  const { svc, basePath } = props;
  const [stockPicking, setStockPicking] = useState<StockPicking>();
  const [serial, setSerial] = useState<ProductSerial>();
  const [qualityChecks, setQualityChecks] = useState<QualityCheck[]>();
  const history = useHistory();
  const [nextQcIdx, setNextQcIdx] = useState<number>();
  const [previousQcIdx, setPreviousQcIdx] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const nextQcIdx =
      qualityChecks && qcIdx && +qcIdx < qualityChecks.length - 1
        ? +qcIdx + 1
        : undefined;
    const previousQcIdx =
      qualityChecks && qcIdx && +qcIdx !== 0 ? +qcIdx - 1 : undefined;
    setNextQcIdx(nextQcIdx);
    setPreviousQcIdx(previousQcIdx);
  }, [qualityChecks, qcIdx]);

  const next = useCallback(() => {
    if (nextQcIdx !== undefined)
      history.push(basePath + "/" + spid + "/" + mlid + "/" + nextQcIdx);
  }, [basePath, history, spid, mlid, nextQcIdx]);

  const previous = useCallback(() => {
    if (previousQcIdx !== undefined)
      history.push(basePath + "/" + spid + "/" + mlid + "/" + previousQcIdx);
  }, [basePath, history, spid, mlid, previousQcIdx]);

  const validate = useCallback(async () => {
    try {
      await svc.validateStockPicking(stockPicking!.id!);
      history.push("/stock-pickings");
    } catch (error) {
      console.error("Failed to validate stock picking", error, stockPicking);
      throw error;
    }
  }, [history, stockPicking, svc]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "l") {
        next();
      } else if (event.key === "h") {
        previous();
      } else if (event.key === "[") {
        history.push(basePath + "/" + spid + "/" + mlid + "/" + 0);
      } else if (event.key === "]" && qualityChecks) {
        history.push(
          basePath + "/" + spid + "/" + mlid + "/" + (qualityChecks.length - 1)
        );
      } else if (
        stockPicking &&
        stockPicking.id &&
        qualityChecks &&
        allChecksPassed(qualityChecks) &&
        event.key === "v"
      ) {
        validate();
      }
    },
    [
      qualityChecks,
      stockPicking,
      validate,
      basePath,
      history,
      mlid,
      next,
      previous,
      spid,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    async function fetchStockPicking(spid: number) {
      try {
        const stockPicking = await svc.readStockPicking(spid);
        if (stockPicking !== undefined) {
          setStockPicking(stockPicking);
          console.log("stock picking", JSON.stringify(stockPicking, null, 2));
        } else {
          throw new Error("Error getting stock picking");
        }
      } catch (error) {
        console.error("error getting stock picking", spid, error);
      }
    }
    if (isNotBlank(spid) && stockPicking === undefined) {
      fetchStockPicking(+spid);
    }
  }, [spid, svc, stockPicking, setStockPicking]);

  useEffect(() => {
    async function readQualityChecks(
      productInternalReference: string,
      sp: StockPicking
    ) {
      try {
        if (Array.isArray(sp.check_ids)) {
          const check_ids = sp.check_ids as any as number[];
          const qcs = await svc.readQualityChecks(check_ids);
          setQualityChecks(sortQualityChecks(productInternalReference, qcs));
        }
      } catch (error) {
        console.error("error getting stock picking info", error);
        setQualityChecks([]);
      }
    }

    if (stockPicking !== undefined && qualityChecks === undefined) {
      readQualityChecks(serial?.product?.internalReference || "", stockPicking);
    }
  }, [stockPicking, serial, svc, qualityChecks, setQualityChecks]);

  useEffect(() => {
    async function readQualityChecks(
      productInternalReference: string,
      sp: StockPicking
    ) {
      try {
        if (Array.isArray(sp.check_ids)) {
          const check_ids = sp.check_ids as any as number[];
          const qcs = await svc.readQualityChecks(check_ids);
          setQualityChecks(sortQualityChecks(productInternalReference, qcs));
        }
      } catch (error) {
        console.error("error getting stock picking info", error);
      }
    }

    const timer = setIntervalAsync(
      async (stockPicking: StockPicking | undefined) => {
        if (stockPicking !== undefined) {
          readQualityChecks(
            serial?.product?.internalReference || "",
            stockPicking
          );
        }
      },
      1000,
      stockPicking
    );
    return () => {
      clearIntervalAsync(timer);
    };
  }, [stockPicking, serial, svc, setQualityChecks]);

  useEffect(() => {
    async function fetchSerial(sp: StockPicking) {
      try {
        if (
          sp !== undefined &&
          Array.isArray(sp.move_line_ids_without_package)
        ) {
          const move_line_ids =
            sp.move_line_ids_without_package as any as number[];
          const moveLines = await svc.readStockMoveLines(move_line_ids);
          if (moveLines !== undefined && moveLines.length === 1) {
            //We only support stock pickings for individual lines over here
            const sml = moveLines[0];
            console.log("stock move line", JSON.stringify(sml, null, 2));

            const lot_id = sml.lot_id as any as IdWithName;
            if (lot_id !== undefined && Array.isArray(lot_id)) {
              const s = await svc.readSerial(lot_id[0]);

              // Get the full product, we need it for the route_ids
              if (s !== undefined) {
                const product = await svc.readProduct(s?.product?.id as any);
                if (product !== undefined) {
                  s.product = product!;
                }
              }

              setSerial(s);
              console.log("got serial", JSON.stringify(s));
            }
          }
        }
      } catch (error) {
        console.error("error getting serial", error);
      }
    }
    if (stockPicking !== undefined && serial === undefined) {
      fetchSerial(stockPicking);
    }
  }, [stockPicking, svc, serial, setSerial]);

  if (spid === undefined) {
    return <div>Stock picking ID not found</div>;
  } else if (mlid === undefined) {
    return <div>Stock move line line ID not found</div>;
  } else if (stockPicking === undefined) {
    return <div>Loading stock picking {spid} ...</div>;
  } else if (qualityChecks === undefined) {
    return (
      <div>
        Loading quality checks for{" "}
        {
          <a
            target="_blank"
            rel="noreferrer"
            href={odooUrl("stock.picking", id(stockPicking.id!)!) || ""}
          >
            {stockPicking.name || name(stockPicking.id as any)}
          </a>
        }
        ...
      </div>
    );
  } else if (qualityChecks.length === 0) {
    return (
      <div>
        No quality checks have been found for{" "}
        {
          <a
            target="_blank"
            rel="noreferrer"
            href={odooUrl("stock.picking", id(stockPicking.id!)!) || ""}
          >
            {stockPicking.name || name(stockPicking.id as any)}
          </a>
        }
        ...
      </div>
    );
  }

  return (
    <div>
      <Switch>
        <Route exact path={basePath + "/" + spid + "/" + mlid}>
          <Redirect to={basePath + "/" + spid + "/" + mlid + "/0"} />
        </Route>

        {qualityChecks.map((qualityCheck, index) => {
          return (
            <Route
              path={basePath + "/" + spid + "/" + mlid + "/" + index}
              key={index}
            >
              <Grid container spacing={1} style={{ padding: 20 }}>
                <Grid item xs={6}>
                  <Breadcrumbs>
                    <a href="/stock-pickings">Overview</a>
                    {stockPicking !== undefined &&
                      stockPicking.id !== undefined && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl("stock.picking", stockPicking.id!)!}
                        >
                          {stockPicking.name}
                        </a>
                      )}
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={1}>
                  <Button
                    data-cy="previous"
                    variant="contained"
                    color="inherit"
                    fullWidth={true}
                    disabled={previousQcIdx === undefined}
                    onClick={previous}
                    startIcon={<SkipPrevious />}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item xs={10}>
                  <Stepper activeStep={index}>
                    {qualityChecks.map((qualityCheck, i) => {
                      const name = qualityCheckCode(qualityCheck);

                      return (
                        <Step
                          key={name}
                          completed={qualityCheck.state === "pass"}
                        >
                          <StepLabel error={qualityCheck.state === "fail"}>
                            <Typography
                              variant="body1"
                              color={
                                i === index ? "text.primary" : "text.disabled"
                              }
                              sx={{
                                fontWeight: i === index ? "bold" : "regular",
                              }}
                            >
                              {qualityCheckCodeWithoutProject(qualityCheck)}
                            </Typography>
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    data-cy="next"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={nextQcIdx === undefined}
                    onClick={next}
                    endIcon={<SkipNext />}
                  >
                    Next
                  </Button>
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Version Number"
                    value={
                      serial === undefined
                        ? ""
                        : getProductVersion(serial.product)
                    }
                    placeholder="Version Number"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    data-cy="finished-product-device-id"
                    fullWidth
                    label="Device ID"
                    disabled={true}
                    value={
                      serial === undefined || serial.deviceId === undefined
                        ? ""
                        : serial.deviceId
                    }
                    placeholder="Device ID"
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Serial"
                    value={
                      serial === undefined || serial.serial === undefined
                        ? ""
                        : serial.serial
                    }
                    placeholder="Serial"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={12}>
                  {qualityCheckCode(qualityCheck) === WI_DOCK_TEST && (
                    <QualityCheckDockTest
                      description="Test that all 5 slots can be used."
                      qualityCheck={qualityCheck}
                      serial={serial}
                      minimumDots={5}
                      removeFromGroupEnabled={false}
                      svc={props.svc}
                      group={{
                        id: "4d0912f0-4443-11eb-b21a-59854bd51688",
                        description: "manufacturing group",
                        name: "1_Manufacturing_Dock",
                      }}
                      onError={(error) => {
                        console.error(
                          "Dock test failed",
                          qualityCheck.title,
                          error
                        );
                        setErrorMessage(error.message);
                      }}
                      onSuccess={() => {
                        next();
                      }}
                      onFail={() => {}}
                      stockPicking={stockPicking}
                    />
                  )}

                  {qualityCheckCode(qualityCheck) ===
                    WI_HARDWARE_VERSION_IDENTIFICATION && (
                    <QualityCheckHardwareVersion
                      qualityCheck={qualityCheck}
                      serial={serial}
                      svc={svc}
                      onSuccess={() => {
                        next();
                      }}
                      onFail={() => {}}
                    />
                  )}

                  {qualityCheckCode(qualityCheck) ===
                    WI_TEST_CRADLE_RESISTANCES && (
                    <QualityCheckTestCradle
                      qualityCheck={qualityCheck}
                      svc={svc}
                      onError={(error) => {
                        console.error("Failed test cradle resistances", error);
                        setErrorMessage(error.message);
                      }}
                      onSuccess={() => {
                        next();
                      }}
                      onFail={() => {}}
                    />
                  )}

                  {serial &&
                    qualityCheckCode(qualityCheck) ===
                      WI_PRINT_LABEL_DOCK_SERVICING && (
                      <QualityCheckPrintLabel
                        qualityCheck={qualityCheck}
                        serial={serial}
                        svc={props.svc}
                        allowSkip={true}
                        onError={(error) => {
                          console.error(
                            "Printing dock label failed",
                            qualityCheck.title,
                            error
                          );
                          setErrorMessage(error.message);
                        }}
                        onSuccess={() => {
                          next();
                        }}
                        onFail={() => {}}
                      />
                    )}

                  {qualityCheckCode(qualityCheck) === WI_DOT_SIGNAL_QUALITY && (
                    <QualityCheckWiTestSensorDot
                      qualityCheck={qualityCheck}
                      serial={serial}
                      svc={svc}
                      onError={(error) => {
                        console.error(
                          "Failed to pass dot signal quality",
                          error
                        );
                        setErrorMessage(error.message);
                      }}
                      onSuccess={() => {
                        next();
                      }}
                      onFail={() => {}}
                    />
                  )}

                  {qualityCheck.title &&
                    !qualityCheck.title.includes(WI_DOCK_TEST) &&
                    !qualityCheck.title.includes(WI_DOT_SIGNAL_QUALITY) &&
                    !qualityCheck.title.includes(WI_TEST_CRADLE_RESISTANCES) &&
                    !qualityCheck.title.includes(
                      WI_HARDWARE_VERSION_IDENTIFICATION
                    ) &&
                    !qualityCheck.title.includes(
                      WI_PRINT_LABEL_DOCK_SERVICING
                    ) && (
                      <QualityCheckPassFail
                        qualityCheck={qualityCheck}
                        svc={props.svc}
                        onError={(error) => {
                          console.error(
                            "Quality check failed",
                            qualityCheck.title,
                            error
                          );
                          setErrorMessage(error.message);
                        }}
                        onSuccess={() => {
                          next();
                        }}
                        onFail={() => {}}
                      />
                    )}
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ padding: 20 }}>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={4}>
                  <QualityAlertButton
                    svc={svc}
                    serials={[serial!]}
                    srcLocationId={WH_QUARANTAINE}
                    qualityCheckId={qualityCheck.id}
                    qualityCheckTitle={qualityCheck.title ?? ""}
                    pickingId={stockPicking.id}
                    message={`Failed quality checks: ${qualityChecks
                      .filter((qc) => qc.state === "fail")
                      .map((qc) => odooUrl("quality.check", qc.id))
                      .join(", ")}`}
                    onClick={async () => {
                      await svc.cancelStockPicking(stockPicking.id!);
                      setStockPicking({ ...stockPicking, state: "cancel" });
                    }}
                    disabled={
                      isScrappableQualityCheck(qualityCheck) ||
                      qualityCheck.state !== "fail" ||
                      stockPicking.state === "cancel" ||
                      stockPicking.state === "done" ||
                      !qualityChecks.some((qc) => qc.state === "fail")
                    }
                    onError={(error) => {
                      console.error("Failed create quality alert", error);
                      setErrorMessage(error.message);
                    }}
                    onDone={() => {}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ScrapButton
                    svc={svc}
                    serials={[serial!]}
                    srcLocationId={WH_QUARANTAINE}
                    onClick={async () => {
                      await svc.cancelStockPicking(stockPicking.id!);
                      setStockPicking({ ...stockPicking, state: "cancel" });
                    }}
                    disabled={
                      !isScrappableQualityCheck(qualityCheck) ||
                      qualityCheck.state !== "fail" ||
                      stockPicking.state === "cancel" ||
                      stockPicking.state === "done" ||
                      !qualityChecks.some((qc) => qc.state === "fail")
                    }
                    onError={(error) => {
                      console.error("Failed to scrap", error);
                      setErrorMessage(error.message);
                    }}
                    onDone={() => {}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <GreenButton
                    label="Validate"
                    fullWidth
                    disabled={
                      !stockPicking.id
                      || !allChecksPassed(qualityChecks)
                      || ["done", "cancel"].includes(stockPicking.state) 
                    }
                    onClick={validate}
                    onError={(error) => {
                      console.error(error);
                      setErrorMessage(error.message);
                    }}
                  />
                </Grid>
              </Grid>
            </Route>
          );
        })}
      </Switch>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={60000}
        onClose={() => {
          setErrorMessage(undefined);
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage(undefined);
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default StockPickingEditor;
