import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OdooOperationsService, {
  AndCriterium,
  ComparatorCriterium,
} from "./erp/manufacturing/OdooOperationsService";
import {
  id,
  name,
  odooUrl,
  OPERATION_TYPE_SERVICING,
  VIRT_PRODUCTION,
  WH_QUARANTAINE,
  WH_STOCK,
} from "./erp/odoo/OdooUtils";
import {
  Alert,
  Button,
  Chip,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  IdWithName,
  OdooModel,
  StockMoveLine,
  StockPicking,
} from "@byteflies/odoo-typescript";
import SerialSearchField from "./SerialSearchField";
import { Add, CheckCircle, Pending, Warning } from "@mui/icons-material";
import { SerialSelectionDialog } from "./Plot/SerialsSelectionDialog";
import {
  canBeServiced,
  getProductNameWithoutInternalReferenceFromId,
} from "./Utils";
import { Location } from "./erp/manufacturing/Product";

type StockPickingState =
  | "draft"
  | "waiting"
  | "confirmed"
  | "assigned"
  | "done"
  | "cancel";

const formatState = (sp: StockPicking) => {
  const poState = (sp as any).state as StockPickingState;
  switch (poState) {
    case "draft":
      return <Chip label="Draft" />;
    case "waiting":
      return <Chip label="Waiting" />;
    case "confirmed":
      return <Chip label="Confirmed" color="primary" />;
    case "assigned":
      return <Chip label="Assigned" color="primary" />;
    case "done":
      return <Chip label="Done" color="success" />;
    case "cancel":
      return <Chip label="Cancel" color="warning" />;
    default:
      return <Chip label={poState} />;
  }
};

const formatQualityIcon = (stockPicking: StockPicking) => {
  if (stockPicking.quality_check_fail === true) {
    return (
      <Tooltip title="At least one quality check has failed">
        <Warning color="error" />
      </Tooltip>
    );
  } else if (
    stockPicking.quality_check_fail === false &&
    stockPicking.quality_check_todo === false &&
    stockPicking.check_ids !== undefined &&
    stockPicking.check_ids.length > 0
  ) {
    return (
      <Tooltip title="No quality checks have failed and none are todo">
        <CheckCircle color="success" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="At least one quality check is not finished yet">
        <Pending color="disabled" />
      </Tooltip>
    );
  }
};

interface StockPickingOverviewProps {
  svc: OdooOperationsService;
}

function StockPickingOverview(props: StockPickingOverviewProps) {
  const { svc } = props;
  const [stockPickings, setStockPickings] = useState<StockPicking[]>();
  const [scannedSerials, setScannedSerials] = useState<StockPicking[]>([]);
  const [selectSerialsDialogOpen, setSelectSerialsDialogOpen] = useState(false);
  const [error, setError] = useState<Error>();
  const history = useHistory();

  const getLocation = async (
    serialId: number
  ): Promise<Location | undefined> => {
    const stockQuants = (await svc.searchStockQuantsByLotId(serialId)).filter(
      (sq) => sq.quantity !== 0
    );
    if (stockQuants.length === 2) {
      const realStockQuant = stockQuants.find(
        (sq) => sq.quantity === 1
      );
      const prodStockQuant = stockQuants.find(
        (sq) =>
          sq.quantity === -1 && id(sq.location_id) === VIRT_PRODUCTION
      );
      if (realStockQuant && prodStockQuant) {
        return {
          id: id(realStockQuant.location_id as IdWithName)!,
          name: name(realStockQuant.location_id as IdWithName)!,
        };
      }
    }
  };

  const openStockPicking = (stockPicking: StockPicking) => {
    const stockMoveLines = stockPicking.move_line_ids_without_package as any[] as StockMoveLine[];
    const stockMoveLineId = stockMoveLines[0].id;
    const url = `/stock-picking/${stockPicking.id}/${stockMoveLineId}`;
    history.push(url);
  }

  useEffect(() => {
    const fetchSp = async () => {
      try {
        const picking_type_id = new ComparatorCriterium(
          "picking_type_id",
          "=",
          OPERATION_TYPE_SERVICING[0]
        );
        const state = new ComparatorCriterium("state", "in", [
          "draft",
          "confirmed",
          "waiting",
          "assigned",
        ]);

        const criterium = new AndCriterium(picking_type_id, state);

        const myStockPickings = await svc.searchStockPickings(
          {
            toDomain(): any[] {
              return criterium.toArray();
            },
          },
          10000
        );

        setStockPickings(myStockPickings);

        // Now read all stock move lines and re-render the stockpickings with the stock move
        // lines id's replaced with the actual lines
        const stockMoveLineIds = myStockPickings
          .map((sm) => sm.move_line_ids_without_package as any as number[])
          .flat();
        const stockMoveLines = await svc.readStockMoveLines(stockMoveLineIds);

        const sml2: StockPicking[] = [];
        for (const myStockPicking of myStockPickings) {
          const myStockMoveLines = stockMoveLines
            .filter((sml) => sml.picking_id !== undefined)
            .filter((sml) => id(sml.picking_id) === id(myStockPicking.id));
          const myClone: StockPicking = {
            ...myStockPicking,
            move_line_ids_without_package: myStockMoveLines as any[],
          };
          sml2.push(myClone);
        }
        setStockPickings(sml2);
        setError(undefined);
      } catch (error) {
        setStockPickings([]);
        console.error("Failed to fetch stock move lines", error);
        setError(error as Error);
      }
    };

    if (stockPickings === undefined) {
      fetchSp();
    }
  }, [stockPickings, svc, setStockPickings]);

  const rows = () => {
    return scannedSerials !== undefined && scannedSerials.length > 0
      ? scannedSerials
      : stockPickings !== undefined && stockPickings.length > 0
      ? stockPickings
      : [];
  };

  return (
    <div>
      <Grid container style={{ padding: 24 }}>
        <Grid item xs={8}>
          <Typography
            variant="h4"
            color="inherit"
            style={{ padding: 24, textAlign: "left" }}
          >
            {name(OPERATION_TYPE_SERVICING)}
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <SerialSearchField
            svc={svc}
            fullWidth={true}
            onClear={() => {
              setScannedSerials([]);
            }}
            onError={(error) => {
              console.error("Something went wrong scanning serial", error);
            }}
            onSerialsSelected={async (serials) => {
              setError(undefined);
              const filteredSerials = serials.filter((s) => s.serial.routecard_servicing);
              console.log("Serials found", JSON.stringify(serials));
              const lotIds = filteredSerials.map((serial) => serial.serial.id);
              if (lotIds.length === 0) {
                return;
              }
              try {
                const picking_type_id = new ComparatorCriterium(
                  "picking_type_id",
                  "=",
                  OPERATION_TYPE_SERVICING[0]
                );
                const lotCriterium = new ComparatorCriterium(
                  "move_line_ids_without_package.lot_id",
                  "in",
                  lotIds
                );

                const criterium = new AndCriterium(
                  picking_type_id,
                  lotCriterium
                );

                const myStockPickings = (await svc.searchStockPickings({
                  toDomain(): any[] {
                    return criterium.toArray();
                  },
                })).filter((sp) => !["done", "cancel"].includes(sp.state));

                if (myStockPickings.length === 0) {
                  setError(
                    new Error(
                      `No stock pickings found for serial number ${serials
                        .map((s) => s.serial.serial)
                        .join(" ,")}`
                    )
                  );
                  setScannedSerials([]);
                } else {
                  const stockMoveLineIds = myStockPickings
                    .map(
                      (sm) =>
                        sm.move_line_ids_without_package as any as number[]
                    )
                    .flat();
                  const stockMoveLines = await svc.readStockMoveLines(
                    stockMoveLineIds
                  );

                  const sml2: StockPicking[] = [];
                  for (const myStockPicking of myStockPickings) {
                    const myStockMoveLines = stockMoveLines
                      .filter((sml) => sml.picking_id !== undefined)
                      .filter(
                        (sml) => id(sml.picking_id) === id(myStockPicking.id)
                      );
                    const myClone: StockPicking = {
                      ...myStockPicking,
                      move_line_ids_without_package: myStockMoveLines as any[],
                    };
                    sml2.push(myClone);
                  }
                  if (sml2.length === 1) {
                    openStockPicking(sml2[0]);
                    return;
                  }
                  setScannedSerials(sml2);
                }
              } catch (error) {
                setStockPickings([]);
                console.error("Failed to fetch stock move lines", error);
                setError(error as Error);
              }
            }}
          />
        </Grid>

        {error !== undefined && (
          <Grid item xs={12}>
            <Alert severity="warning">{error.message}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Reference</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Lot/Serial number(s)</TableCell>
                  <TableCell>Open</TableCell>
                  <TableCell>Quality Checks</TableCell>
                  <TableCell>State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows()
                  .map((row) => row as StockPicking)
                  .map((row) => (
                    <TableRow key={row.id} data-cy={row.id}>
                      <TableCell component="th" scope="row">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl("stock.picking", id(row.id!)!) || ""}
                        >
                          {row.name || name(row.id as any)}
                        </a>
                      </TableCell>
                      <TableCell>
                        {row.move_line_ids_without_package
                          ?.map((ml) => ml as any as StockMoveLine)
                          .filter((ml) => Array.isArray(ml.product_id))
                          .map((ml) => ml.product_id as any as IdWithName)
                          .map((product) => (
                            <>
                              {getProductNameWithoutInternalReferenceFromId(
                                product
                              )}
                            </>
                          ))}
                      </TableCell>
                      <TableCell>
                        {row.move_line_ids_without_package
                          ?.map((ml) => ml as any as StockMoveLine)
                          .filter((ml) => Array.isArray(ml.product_id))
                          .map((ml) => ml.lot_id as any as IdWithName)
                          .map((lot) => (
                            <a
                              key={id(lot)}
                              target="_blank"
                              rel="noreferrer"
                              href={odooUrl(
                                "stock.lot" as OdooModel,
                                id(lot) || -1
                              )}
                            >
                              {name(lot)}
                            </a>
                          ))}
                      </TableCell>
                      <TableCell>
                        {row.id !== undefined && (
                          <Button
                            data-cy={"edit-stock-picking"}
                            variant="contained"
                            disabled={
                              isNaN(row.id) ||
                              row.move_line_ids_without_package === undefined ||
                              row.move_line_ids_without_package.length === 0
                            }
                            onClick={() => openStockPicking(row)}
                          >
                            Open
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{formatQualityIcon(row)}</TableCell>
                      <TableCell>{formatState(row)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Tooltip title="Scan serials to create a new service quarantined products flow">
        <Fab
          data-cy="add-stock-picking-dialog"
          sx={{
            position: "fixed",
            bottom: "15px",
            right: "15px",
          }}
          color="primary"
          aria-label="add"
          onClick={() => {
            setError(undefined);
            setSelectSerialsDialogOpen(true);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
      <SerialSelectionDialog
        open={selectSerialsDialogOpen}
        svc={svc}
        onClose={() => {
          setSelectSerialsDialogOpen(false);
        }}
        serialFilter={async (serial) => {
          if (!canBeServiced(serial.product.internalReference)) {
            throw Error(`${serial.serial} cannot be serviced`);
          }
          if (!svc.isOdooProductSerial(serial)) {
            throw Error("Product is not an Odoo serial");
          }
          const location = await getLocation(serial.id);
          if (!location || location.id !== WH_QUARANTAINE) {
            throw Error(`${serial.serial} is not in quarantine`);
          }
          if (!serial.routecard_servicing) {
            return false;
          }
          return true;
        }}
        onOK={async (serials) => {
          const stockPickingSerials =
            stockPickings
              ?.flatMap(
                (sp) =>
                  sp.move_line_ids_without_package as unknown as StockMoveLine[]
              )
              .map((sml) => id(sml.lot_id as IdWithName)) ?? [];
          let stockPickingToOpen = null;
          for (const serial of serials) {
            if (stockPickingSerials.includes(serial.serial.id)) {
              console.log(
                `Already found a stock picking for ${serial.serial.serial}, not creating a new one`
              );
              continue;
            }
            const sp = await svc.createStockPick(
              [serial.serial],
              WH_QUARANTAINE,
              WH_STOCK,
              id(OPERATION_TYPE_SERVICING),
              false
            );
            if (serials.length === 1) {
              stockPickingToOpen = sp;
            }
            console.log("Created stock picking", JSON.stringify(sp));
          }
          if (stockPickingToOpen) {
            const stockPickingToOpen2 = await svc.readStockPicking(stockPickingToOpen.id!);
            const stockMoveLineIds = stockPickingToOpen2!.move_line_ids_without_package as any[];
            const stockMoveLines = await svc.readStockMoveLines(
              stockMoveLineIds
            );
            const stockPickingToOpen2Clone: StockPicking = {
              ...stockPickingToOpen2 as any,
              move_line_ids_without_package: stockMoveLines as any[]
            }
            console.log(`stockPickingToOpen2Clone: ${JSON.stringify(stockPickingToOpen2Clone, null, 2)}`);
            openStockPicking(stockPickingToOpen2Clone!);
          }
          setSelectSerialsDialogOpen(false);
          setScannedSerials([]);
          setStockPickings(undefined);
        }}
      />
    </div>
  );
}

export default StockPickingOverview;
