import { useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import { ProductSerial } from "../erp/manufacturing/Product";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { VIRT_SCRAP } from "../erp/odoo/OdooUtils";

interface ScrapButtonProps {
  svc: OdooOperationsService;
  serials: ProductSerial[];
  srcLocationId: number;
  disabled?: boolean;
  onError(error: Error): void;
  onClick(): Promise<void>;
  onDone(): void;
}

export function ScrapButton(props: ScrapButtonProps) {
  const { svc, serials, srcLocationId, disabled, onError, onClick, onDone } =
    props;

  const [state, setState] = useState<undefined | "busy" | "success" | "failed">(
    undefined
  );
  const [scrapOrderName, setScrapOrderName] = useState<string>();

  return (
    <Button
      variant="contained"
      color="error"
      fullWidth
      disabled={disabled || state === "busy"}
      onClick={async () => {
        setState("busy");
        try {
          await onClick();
          for (const serial of serials) {
            const scrapId = await svc.scrapSerial(serial, srcLocationId, VIRT_SCRAP);
            const scrapOrderName = (await svc.readStockScrap(scrapId)).name;
            setScrapOrderName(scrapOrderName);
          }
          onDone();
          setState("success");
        } catch (error) {
          setState("failed");
          onError(error as Error);
        }
      }}
    >
      {state === "busy" && <CircularProgress size="1em" />}
      {state === "failed" && <Warning />}
      {state === "success" && <CheckCircle />}
      &nbsp;Scrap{scrapOrderName ? ` (${scrapOrderName})` : null}
    </Button>
  );
}
