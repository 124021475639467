import {
  getOdooSettings,
  storeOdooSession,
  getOdooSession as getLocalStorageSession,
  clearOdooSession,
} from "../../Settings";
import {
  getGtinByInternalReference,
  getProductInternalReferenceFromName,
  getProductNameWithoutInternalReference,
  odooProductToRoutecardProduct,
  productRefToRoutecardProduct,
} from "../../Utils";
import { isBlank, isNotBlank } from "../../StringUtils";
import {
  BillOfMaterial,
  BillOfMaterialType,
  Tracking,
  Product,
  ProductionOrder,
  ProductSerial,
  ProductionOrderLine,
  MrpProductionState,
  QualityCheck,
  ProductionOrderTrace,
  Location,
  StockMove,
} from "../manufacturing/Product";
import {
  ReadProduction,
  StockMove as OdooStockMove,
  StockProductionLot,
  StockMoveLine,
  AddNewRecordCommand,
  OdooMethod,
  ReadProductionLot,
  StockScrap,
  StockLocation,
  StockQuant,
  StockPicking,
  MrpUnbuild,
  QualityPoint,
  IdWithName,
  QualityCheck as OdooQualityCheck,
  ProductionOrder as OdooProductionOrder,
  GetProduct,
  Many2One,
  OdooModel,
  CallParams,
  ProcurementGroup,
  CreateLocationArgs,
  parseOdooDatetime,
  GetBillOfMaterial,
  ReadBomLine,
  Many2Many,
  dateToOdooDateTime,
  ReplaceAllExistingRecordsCommand,
} from "@byteflies/odoo-typescript";
import OdooClient, {
  Session,
  CallButtonResult,
  OdooDomainPart,
} from "../odoo/OdooClient";
import {
  COMPANY_ID_BYTEFLIES,
  VIRT_PRODUCTION,
  WH_BYTEFLIES_MANUFACTURING,
  WH_STOCK,
  getCurrentLocation,
  getCurrentLocations,
  id,
  name,
} from "../odoo/OdooUtils";
import {
  parseSerial,
  isLot,
  isSerial,
  parseDateFromSerial,
  parseDateFromLot,
} from "@byteflies/byteflies-serials";

const MANUFACTURING_ROUTE_ID = 5;

interface RouteCardStockPicking extends StockPicking {
    routecard_url?: false | string;
}

export interface ExtendedProduct extends Product {}

export type Operator = "=" | "!=" | "<=" | ">=" | "in" | "not in";

export class ComparatorCriterium implements Criterium {
  fieldName: string;
  operator: Operator;
  value: any;

  constructor(fieldName: string, operator: Operator, value: any) {
    this.fieldName = fieldName;
    this.operator = operator;
    this.value = value;
  }

  toArray() {
    return [this.fieldName, this.operator, this.value];
  }
}

export class AndCriterium implements Criterium {
  criteria: Criterium[];
  constructor(...criteria: Criterium[]) {
    this.criteria = criteria;
  }

  toArray() {
    if (this.criteria.length === 0) {
      return [];
    } else if (this.criteria.length === 1) {
      return [this.criteria[0].toArray()];
    } else if (this.criteria.length === 2) {
      return ["&", this.criteria[0].toArray(), this.criteria[1].toArray()];
    } else if (this.criteria.length === 3) {
      return [
        "&",
        this.criteria[0].toArray(),
        "&",
        this.criteria[1].toArray(),
        this.criteria[2].toArray(),
      ];
    } else {
      throw new Error("More criteria are not supported yet");
    }
  }
}

export class OrCriterium implements Criterium {
  criteria: Criterium[];
  constructor(...criteria: Criterium[]) {
    this.criteria = criteria;
  }

  toArray() {
    if (this.criteria.length === 0) {
      return [];
    } else if (this.criteria.length === 1) {
      return [this.criteria[0].toArray()];
    } else if (this.criteria.length === 2) {
      return ["|", this.criteria[0].toArray(), this.criteria[1].toArray()];
    } else if (this.criteria.length === 3) {
      return [
        "|",
        this.criteria[0].toArray(),
        "|",
        this.criteria[1].toArray(),
        this.criteria[2].toArray(),
      ];
    } else {
      throw new Error("More criteria are not supported yet");
    }
  }
}

export interface Criterium {
  toArray(): any[];
}

export interface Filter {
  toDomain(): any[];
}

export interface ExtendedProductSerial extends ProductSerial {
  product: ExtendedProduct;
}

export interface SerialFilter {
  serial?: string;
  ref?: string;
  productRefs?: string[]; //BF-number of product
  versionNumber?: string;
}

export interface IOdooOperationsService {
  listProductionOrders(filter: Filter): Promise<ProductionOrder[]>;

  searchProductionOrderBySerialId(
    serialId: number
  ): Promise<ProductionOrder | null>;

  searchProductionOrderBySerials(
    serials: ProductSerial[]
  ): Promise<ProductionOrder[]>;

  searchSerialsByName(filter: SerialFilter): Promise<ProductSerial[]>;

  saveQualityCheck(qualityCheck: QualityCheck, success: boolean): Promise<void>;

  markStockProductionAsDone(
    po: ProductionOrder
  ): Promise<boolean | CallButtonResult>;
}

export interface ProcurementGroupWithInfo {
  products: GetProduct[];
  group: ProcurementGroup;
}

export interface MyProductionOrder {
  product: GetProduct;
  po: OdooProductionOrder;
  bom: GetBillOfMaterial;
  bom_lines: ReadBomLine[];
}

export interface RouteCardReadProductionLot extends ReadProductionLot {
    routecard_servicing: boolean;
}

export default class OdooOperationsService implements IOdooOperationsService {
  // session is a promise, we want to make sure we don't authenticate multiple times
  session: Promise<Session> | undefined = undefined;

  public getOdooClient() {
    const odooSettings = getOdooSettings();

    if (odooSettings !== undefined && odooSettings.url !== undefined) {
      const reactAppProxy = process.env.REACT_APP_PROXY;
      if (!reactAppProxy) {
        throw new Error("REACT_APP_PROXY not configured");
      }
      const odooClient = new OdooClient(
        reactAppProxy,
        odooSettings.dbName,
        odooSettings.url
      );
      return odooClient;
    }
    console.error(
      "Odoo settings are not configured",
      JSON.stringify(odooSettings)
    );
    throw new Error("Odoo settings are not defined");
  }

  public async getOdooSession(): Promise<Session> {
    let session: Session | null;

    if (this.session) {
      session = await this.session;
    } else {
      session = getLocalStorageSession();
    }

    if (session !== null && session.cookies.length !== 0) {
      const expiresAttribute = session.cookies[0]
        .split("; ")
        .find((attribute) => attribute.startsWith("Expires="))
        ?.split("=")[1];
      const expiresDate = expiresAttribute
        ? new Date(expiresAttribute)
        : undefined;
      if (expiresDate && expiresDate > new Date(Date.now())) {
        return session;
      }
    } else {
      console.log("Odoo session expired, clearing session");
      this.session = undefined;
      clearOdooSession();
    }

    const odooSettings = getOdooSettings();
    if (odooSettings) {
      const odooClient = this.getOdooClient();
      const session = odooClient.login(
        odooSettings.username,
        odooSettings.password
      );
      storeOdooSession(session);
      this.session = session;
      return session;
    }
    throw new Error("Unable to login");
  }

  private odooQcToExtendedQualityCheck(qc: OdooQualityCheck) {
    const eqc: QualityCheck = {
      id: qc.id!,
      name: qc.name,
      display_name: qc.display_name,
      note: qc.note,
      test_type_id: qc.test_type_id,
      test_type: qc.test_type,
      title: qc.title,
      sequence: qc.sequence,
      state: qc.quality_state,
      norm_unit: qc.norm_unit,
      tolerance_min: qc.tolerance_min,
      tolerance_max: qc.tolerance_max,
      measure: qc.measure,
      point_id:
        qc.point_id !== undefined ? (qc.point_id as IdWithName)[0] : undefined,
    };
    return eqc;
  }

  public async getNextSequenceByCode(sequenceCode: string) {
    try {
      const odooClient = this.getOdooClient();
      const session = await this.getOdooSession();
      const params: CallParams = {
        model: "ir.sequence",
        method: "next_by_code" as OdooMethod,
        args: [sequenceCode],
        kwargs: { context: odooClient.sessionToContext(session) },
      };
      const result = odooClient.call<string>(
        session,
        "/web/dataset/call_kw",
        params
      );
      return result;
    } catch (error) {
      console.error("Failed to get next sequence", error);
      throw error;
    }
  }

  public async readQualityChecks(check_ids: number[]): Promise<QualityCheck[]> {
    if (check_ids === undefined || check_ids.length === 0) {
      return [];
    }

    const qualityCheckFields = [
      "product_id",
      "name",
      "title",
      "note",
      "team_id",
      "picking_id",
      "point_id",
      "test_type",
      "test_type_id",
      "measure_success",
      "company_id",
      "measure",
      "norm_unit",
      "display_name",
      "quality_state",
      "norm_unit",
      "tolerance_min",
      "tolerance_max",
      "lot_id",
    ];
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    try {
      const qualityChecks = await odooClient.read<OdooQualityCheck[]>(
        session,
        [check_ids, qualityCheckFields],
        "quality.check"
      );
      if (qualityChecks !== undefined) {
        return qualityChecks.map((qc) => this.odooQcToExtendedQualityCheck(qc));
      } else {
        return [];
      }
    } catch (error) {
      console.error("Failed to list products", error);
      this.session = undefined;
      throw error;
    }
  }

  public async searchManufacturableProducts(): Promise<GetProduct[]> {
    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;
      try {
        const productsResult = await odooClient.searchRead<GetProduct>(
          session,
          [
            ["type", "in", ["consu", "product"]],
            ["route_ids", "in", [MANUFACTURING_ROUTE_ID]],
          ],
          [
            "id",
            "default_code",
            "name",
            "type",
            "tracking",
            "bom_ids",
            "bom_line_ids",
            "company_id",
          ],
          undefined,
          80,
          undefined,
          "product.product" as OdooModel
        );

        if (
          productsResult !== undefined &&
          productsResult.records !== undefined &&
          productsResult.records.length > 0
        ) {
          const products = productsResult.records;
          const productIds = products.map((p) => p.id);
          const productNames = await odooClient.getProductNames(
            session,
            productIds
          );
          const productsWithNames = products.map((p, i) => ({
            ...p,
            name: productNames[i][1],
          }));
          return productsWithNames;
        }
      } catch (error) {
        console.error("Failed to search products", error);
        this.session = undefined;
        throw error;
      }
    }
    return [];
  }

  public async saveQualityCheck(
    qualityCheck: QualityCheck,
    success: boolean
  ): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const method: OdooMethod = success ? "do_pass" : "do_fail";
    const qc = qualityCheck as QualityCheck;
    if (isNaN(qc.id) || qc.id < 0) {
      throw new Error("quality check id is undefined");
    }
    const session = await this.getOdooSession()!;
    await odooClient.callButtonIds(session, "quality.check", method, [qc.id]);
  }

  public async saveQualityCheckMeasure(
    qualityCheck: QualityCheck
  ): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    await odooClient.callButtonIds(session, "quality.check", "do_measure", [
      qualityCheck.id,
    ]);
  }

  public async writeQualityCheck(
    qualityCheck: QualityCheck,
    additionalNote: string | undefined,
    measure?: number
  ): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    const qcClone = {} as any;
    if (additionalNote !== undefined) {
      qcClone.additional_note = additionalNote;
    }
    if (measure !== undefined) {
      qcClone.measure = measure;
    }
    await odooClient.write(session, qualityCheck.id, qcClone, "quality.check");
  }

  public async validateStockScrap(
    stockScrapId: number,
    scrap: StockScrap
  ): Promise<boolean> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    const callButtonResult = await odooClient.callButtonIds(
      session,
      "stock.scrap",
      "action_validate",
      [stockScrapId]
    );

    if (typeof callButtonResult === "boolean") {
      return callButtonResult as boolean;
    } else if (
      typeof callButtonResult === "object" &&
      ((callButtonResult as CallButtonResult).res_model as string) ===
        "stock.warn.insufficient.qty.scrap"
    ) {
      const r = callButtonResult as CallButtonResult;

      const ctx = odooClient.sessionToContext(session);
      ctx.active_id = stockScrapId;
      ctx.active_ids = [stockScrapId];
      ctx.active_model = "stock.scrap";
      ctx.default_scrap_id = stockScrapId;
      ctx.default_quantity = 1;
      ctx.default_product_id = scrap.product_id as any as number;
      ctx.default_product_uom_name = "Units";
      ctx.default_location_id = scrap.location_id;

      const kwargs = {
        context: ctx,
      };
      const resCreated = await odooClient.createWithKwargs(
        session,
        [{}],
        r.res_model,
        kwargs
      );
      const callDoneResult = await odooClient.callButtonIds(
        session,
        r.res_model,
        "action_done",
        [resCreated]
      );
      if (typeof callDoneResult === "boolean") {
        return callDoneResult as boolean;
      } else {
        throw new Error("Unable to handle stock.warn.insufficient.qty.scrap");
      }
    }
    return false;
  }

  public async validateStockPicking(stockPickingId: number): Promise<boolean> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    const setQuantitiesResult = await odooClient.callButtonIds(
      session,
      "stock.picking",
      "action_set_quantities_to_reservation",
      [stockPickingId],
    );

    if (typeof setQuantitiesResult !== "boolean") {
      throw new Error();
    }

    const validateResult = await odooClient.callButtonIds(
      session,
      "stock.picking",
      "button_validate",
      [stockPickingId],
      {
        skip_immediate: true,
        skip_backorder: true,
      },
    );

    if (typeof validateResult === "boolean") {
      return validateResult as boolean;
    } else {
      throw new Error();
    }
  }

  public async cancelStockPicking(stockPickingId: number): Promise<boolean> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    const callButtonResult = await odooClient.callButtonIds(
      session,
      "stock.picking",
      "action_cancel",
      [stockPickingId]
    );

    if (typeof callButtonResult === "boolean") {
      return callButtonResult as boolean;
    }
    return false;
  }

  public async getSerial(
    product: Product,
    serial: string
  ): Promise<ProductSerial | undefined> {
    const extProduct = product as ExtendedProduct;

    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;
      const lot = await odooClient.searchStockProductionLotByProductAndName(
        session,
        extProduct.id,
        serial
      );
      if (lot !== undefined && lot.length === 1) {
        const l = lot[0];
        return {
          product: product,
          serial: l.name || "",
          deviceId: l.ref || "",
          udi: serialToUdi(l, extProduct),
          id: l.id,
          company_id: l.company_id[0],
        } as ProductSerial;
      }
    }
    return undefined;
  }

  public isOdooProductSerial(serial: ProductSerial): boolean {
    if (serial === undefined) {
      return false;
    } else if (serial.id === undefined || serial.id < 0) {
      return false;
    } else {
      return true;
    }
  }

  public isOdooProductionOrder(po: ProductionOrder) {
    if (po === undefined) {
      return false;
    }
    if (po.id === undefined || po.id < 0) {
      return false;
    } else {
      return true;
    }
  }

  public isOdooProduct(product: Product): product is ExtendedProduct {
    const extProduct = product as ExtendedProduct;
    if (extProduct.id === undefined || extProduct.id < 0) {
      return false;
    } else {
      return true;
    }
  }

  public async serialExists(serial: ProductSerial): Promise<boolean> {
    if (serial === undefined || isBlank(serial.serial)) {
      throw new Error("Serial is invalid");
    }

    const extProduct = serial.product;
    if (extProduct.id === undefined) {
      throw new Error("Not an odoo product");
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession();
    if (session === undefined) {
      throw new Error();
    }

    const domain: OdooDomainPart[] = isNotBlank(serial.deviceId)
      ? [
          "&",
          ["product_id", "=", extProduct.id],
          "|",
          ["name", "=", serial.serial!],
          ["ref", "=", serial.deviceId!],
        ]
      : [
          "&",
          ["product_id", "=", extProduct.id],
          ["name", "=", serial.serial!],
        ];
    const lots = await odooClient.searchRead<StockProductionLot>(
      session,
      domain,
      [],
      undefined,
      10,
      undefined,
      "stock.lot" as OdooModel
    );
    if (lots !== undefined && lots.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public async createProductSerial(
    serial: ProductSerial
  ): Promise<ProductSerial> {
    if (this.isOdooProductSerial(serial)) {
      throw new Error("Serial is already saved");
    }
    const extProduct = serial.product as ExtendedProduct;
    if (extProduct.id === undefined) {
      throw new Error("Not an odoo product");
    }

    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;
      const spl: StockProductionLot = {
        product_id: extProduct.id,
        name: serial.serial || serial.udi || "",
        company_id: extProduct.company_id || session.user.company_id,
        note: "",
        ref: serial.deviceId || "",
      };

      const spl_id = await odooClient.create(
        session,
        [spl],
        "stock.lot" as OdooModel
      );

      const productfullname =
        extProduct.internalReference === undefined
          ? extProduct.name
          : `[${extProduct.internalReference}] ${extProduct.name}`;

      const rpl: RouteCardReadProductionLot = {
        product_id: [extProduct.id, productfullname],
        name: serial.serial || serial.udi || "",
        ref: serial.deviceId || "",
        company_id: [extProduct.company_id, "?"],
      } as RouteCardReadProductionLot;
      const converted = odooSpltoProductSerial(rpl);

      const clone: ProductSerial = {
        ...serial,
        id: spl_id,
        company_id: spl.company_id,
        product: extProduct,
        udi: converted.udi,
        deviceId: spl.ref || "",
        creationDate: converted.creationDate,
      };
      return clone;
    }
    throw new Error();
  }

  public async createProcurementGroup(
    procurementGroup: ProcurementGroup
  ): Promise<ProcurementGroup> {
    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;

      const grp_id = await odooClient.create(
        session,
        [procurementGroup],
        "procurement.group"
      );

      const result = await odooClient.read<ProcurementGroup[]>(
        session,
        [[grp_id], []],
        "procurement.group"
      );
      if (
        result !== undefined &&
        Array.isArray(result) &&
        result.length === 1
      ) {
        return result[0];
      } else {
        return procurementGroup;
      }
    }
    throw new Error();
  }

  public async createStockScrap(scrap: StockScrap): Promise<StockScrap> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    const scrap_id = await odooClient.create(session, [scrap], "stock.scrap");
    const clone: StockScrap = {
      ...scrap,
      id: scrap_id,
    } as StockScrap;
    return clone;
  }

  public async validateUnbuildOrder(unbuildOrderId: number): Promise<boolean> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    const r = await odooClient.callButtonIds(
      session,
      "mrp.unbuild",
      "action_validate",
      [unbuildOrderId]
    );
    return r as boolean;
  }

  public async readStockPicking(
    stockPickingId: number
  ): Promise<StockPicking | undefined> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    const result = await odooClient.read<StockPicking[]>(
      session,
      [
        [stockPickingId],
        [
          "priority",
          "name",
          "display_name",
          "location_id",
          "location_dest_id",
          "partner_id",
          "user_id",
          "scheduled_date",
          "date_deadline",
          "origin",
          "group_id",
          "backorder_id",
          "picking_type_id",
          "company_id",
          "state",
          "check_ids",
          "quality_check_todo",
          "quality_check_fail",
          "quality_alert_count",
          "move_line_ids_without_package",
          "move_ids_without_package",
        ],
      ],
      "stock.picking"
    );
    if (result !== undefined && Array.isArray(result) && result.length === 1) {
      return result[0];
    } else {
      return undefined;
    }
  }

  public async read<T>(model: OdooModel, id: number): Promise<T | undefined> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    const result = await odooClient.read<T[]>(session, [[id], []], model);
    if (result !== undefined && Array.isArray(result) && result.length === 1) {
      return result[0];
    } else {
      return undefined;
    }
  }

  public async readStockMoveLines(
    stockMoveLineIds: number[]
  ): Promise<StockMoveLine[]> {
    if (stockMoveLineIds.length === 0) {
      return [];
    }
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    const result = await odooClient.read<StockMoveLine[]>(
      session,
      [
        stockMoveLineIds,
        [
          "product_id",
          "company_id",
          "move_id",
          "picking_id",
          "product_uom_category_id",
          "location_id",
          "location_dest_id",
          "package_id",
          "result_package_id",
          "owner_id",
          "state",
          "lot_id",
          "lot_name",
          "is_locked",
          "qty_done",
        ],
      ],
      "stock.move.line"
    );
    if (result !== undefined && Array.isArray(result)) {
      return result;
    } else {
      return [];
    }
  }

  public async searchStockQuantsBySerials(
    serials: ProductSerial[],
    locationIds: number[]
  ): Promise<StockQuant[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;
    const fields = [
      "id",
      "tracking",
      "product_id",
      "location_id",
      "lot_id",
      "package_id",
      "owner_id",
      "quantity",
      "inventory_quantity",
      "available_quantity",
      "product_uom_id",
      "currency_id",
      "value",
      "company_id",
    ];
    const lot_ids = serials.map((s) => s.id!);
    const quants = await odooClient.searchRead<StockQuant>(
      session,
      ["&", ["lot_id", "in", lot_ids], ["location_id", "in", locationIds]],
      fields,
      undefined,
      250,
      undefined,
      "stock.quant"
    );
    if (
      quants === undefined ||
      quants.records === undefined ||
      quants.records.length === 0 ||
      quants.length === 0
    ) {
      return [];
    }

    return quants.records;
  }

  public async searchStockQuantsByLotId(lot_id: number): Promise<StockQuant[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;
    const fields = [
      "id",
      "tracking",
      "product_id",
      "location_id",
      "lot_id",
      "package_id",
      "owner_id",
      "quantity",
      "inventory_quantity",
      "available_quantity",
      "product_uom_id",
      "currency_id",
      "value",
      "company_id",
    ];
    const quants = await odooClient.searchRead<StockQuant>(
      session,
      [["lot_id", "=", lot_id]],
      fields,
      undefined,
      100,
      undefined,
      "stock.quant"
    );
    if (
      quants === undefined ||
      quants.records === undefined ||
      quants.records.length === 0 ||
      quants.length === 0
    ) {
      return [];
    }

    return quants.records;
  }

  public async writeStockMove(
    id: number,
    stockMove: OdooStockMove
  ): Promise<OdooStockMove> {
    if (stockMove === undefined || isNaN(id)) {
      throw new Error("Incorrect stock move");
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    await odooClient.write(session, id, stockMove, "stock.move");

    return stockMove;
  }

  public async writeStockMoveLine(
    id: number,
    stockMoveLine: StockMoveLine
  ): Promise<StockMoveLine> {
    if (stockMoveLine === undefined || isNaN(id)) {
      throw new Error("Incorrect stock move line");
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    await odooClient.write(session, id, stockMoveLine, "stock.move.line");

    return stockMoveLine;
  }

  public async writeProductionOrder2(
    po_id: number,
    po: OdooProductionOrder
  ): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;
    await odooClient.writeProductionOrder(
      session,
      po_id,
      po as OdooProductionOrder
    );
  }

  public async writeProductionOrder(
    po: ProductionOrder,
    serial: ProductSerial
  ): Promise<void> {
    if (po === undefined || po.id === undefined) {
      throw new Error("Incorrect PO");
    }
    if (serial === undefined || serial.id === undefined) {
      throw new Error(`Incorrect serial: ${serial.serial}`);
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    await odooClient.writeProductionOrder(session, po.id, {
      qty_producing: po.quantity,
      lot_producing_id: serial.id as Many2One<StockProductionLot>,
    } as OdooProductionOrder);
  }

  public async write(model: OdooModel, id: number, obj: object): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    await odooClient.write(session, id, obj as any, model);
  }

  public async postMessage(
    model: OdooModel,
    ids: number[],
    body: string
  ): Promise<void> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    await odooClient.postMessage(session, ids, model, body);
  }

  public async confirmProductionOrder(
    po: ProductionOrder
  ): Promise<ProductionOrder> {
    const prod_order_id = po.id;
    if (prod_order_id === undefined) {
      throw new Error();
    }

    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;
      await odooClient.confirmProductionOrder(session, prod_order_id);
      return po;
    } else {
      throw new Error();
    }
  }

  public async markStockProductionAsDone(
    po: ProductionOrder,
    checkFailedQcs?: boolean
  ): Promise<boolean | CallButtonResult> {
    const prod_order_id = po.id;
    if (prod_order_id === undefined) {
      throw new Error();
    }

    const odooClient = this.getOdooClient();
    if (odooClient !== undefined) {
      const session = await this.getOdooSession()!;
      const context = { check_failed_qcs: checkFailedQcs === false ? false : true };
      return await odooClient.callButtonIds(
        session,
        "mrp.production",
        "button_mark_done",
        [prod_order_id],
        context
      );
    } else {
      throw new Error();
    }
  }

  public async listProductionOrders(
    filter: Filter
  ): Promise<ProductionOrder[]> {
    const poFields = [
      "name",
      "date_planned_start",
      "date_deadline",
      "product_id",
      "product_uom_id",
      "lot_producing_id",
      "bom_id",
      "origin",
      "user_id",
      "reservation_state",
      "product_qty",
      "company_id",
      "state",
      "check_ids",
      "location_src_id",
      "location_dest_id",
      "quality_check_fail",
      "quality_check_todo",
      "workorder_ids",
    ];
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const odooPos = await odooClient.searchRead<ReadProduction>(
      session,
      filter.toDomain().concat([["user_id", "=", session.user.uid]]),
      poFields,
      undefined,
      250,
      undefined,
      "mrp.production"
    );
    if (
      odooPos === undefined ||
      odooPos.records === undefined ||
      odooPos.records.length === 0 ||
      odooPos.length === 0
    ) {
      return [];
    }

    return odooPos.records.map((po) => this.odooPoToRoutecard(po, []));
  }

  public async listUnbuildOrders(filter: Filter): Promise<MrpUnbuild[]> {
    const fields = ["name", "product_id", "mo_id", "lot_id", "state"];
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const response = await odooClient.searchRead(
      session,
      filter.toDomain(),
      fields,
      undefined,
      100,
      undefined,
      "mrp.unbuild"
    );

    return response.length === 0 ? [] : (response.records as MrpUnbuild[]);
  }

  public async listStockMoveLines(filter: Filter): Promise<StockMoveLine[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;
    const fields = [
      "date",
      "reference",
      "origin",
      "state",
      "company_id",
      "picking_id",
      "product_uom_category_id",
      "product_id",
      "location_id",
      "location_dest_id",
      "product_uom_id",
      "lot_id",
      "display_name",
      "move_id",
    ];
    const lines = await odooClient.searchRead<StockMoveLine>(
      session,
      filter.toDomain(),
      fields,
      undefined,
      100,
      undefined,
      "stock.move.line"
    );
    if (
      lines === undefined ||
      lines.records === undefined ||
      lines.records.length === 0 ||
      lines.length === 0
    ) {
      return [];
    }

    return lines.records;
  }

  public async searchStockPickings(
    filter: Filter,
    limit?: number
  ): Promise<StockPicking[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;
    const fields = [
      "move_type",
      "location_id",
      "location_dest_id",
      "picking_type_id",
      "move_line_ids",
      "move_line_ids_without_package",
      "scheduled_date",
      "origin",
      "owner_id",
      "quality_alert_count",
      "quality_check_fail",
      "quality_check_todo",
      "quality_alert_ids",
      "check_ids",
      "state",
      "name",
      "routecard_url",
    ];
    const lines = await odooClient.searchRead<RouteCardStockPicking>(
      session,
      filter.toDomain(),
      fields,
      undefined,
      limit ?? 100,
      "date DESC, name DESC",
      "stock.picking"
    );
    if (
      lines === undefined ||
      lines.records === undefined ||
      lines.records.length === 0 ||
      lines.length === 0
    ) {
      return [];
    }

    return lines.records.filter((sp) => sp.routecard_url !== false);
  }

  public async getLocations(domain: any[]) {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const quants = await odooClient.searchRead<StockLocation>(
      session,
      domain,
      [],
      undefined,
      100,
      undefined,
      "stock.location"
    );
    if (
      quants === undefined ||
      quants.records === undefined ||
      quants.records.length === 0 ||
      quants.length === 0
    ) {
      return [];
    }

    return quants.records;
  }
  public async getCurrentLocation(serial: ProductSerial) {
    const quants = await this.searchStockQuantsBySerial(serial);
    const currentLocation = getCurrentLocation(quants);
    if (
      currentLocation !== undefined &&
      Array.isArray(currentLocation.location_id)
    ) {
      const loc = currentLocation.location_id as IdWithName;
      const l: Location = {
        id: loc[0],
        name: loc[1],
      };
      return l;
    } else {
      console.log("Unable to define current location", JSON.stringify(quants));
      return undefined;
    }
  }

  public async getCurrentLocations(serial: ProductSerial) {
    const quants = await this.searchStockQuantsBySerial(serial);
    return getCurrentLocations(quants);
  }

  public async searchStockQuantsBySerial(
    serial: ProductSerial
  ): Promise<StockQuant[]> {
    if (!this.isOdooProductSerial(serial)) {
      console.error("Not an odoo serial", JSON.stringify(serial));
      throw new Error("Not an odoo serial");
    }
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;
    const fields = [
      "id",
      "tracking",
      "product_id",
      "location_id",
      "lot_id",
      "package_id",
      "owner_id",
      "quantity",
      "inventory_quantity",
      "available_quantity",
      "product_uom_id",
      "currency_id",
      "value",
      "company_id",
    ];
    const quants = await odooClient.searchRead<StockQuant>(
      session,
      [["lot_id", "=", serial.id]],
      fields,
      undefined,
      100,
      undefined,
      "stock.quant"
    );
    if (
      quants === undefined ||
      quants.records === undefined ||
      quants.records.length === 0 ||
      quants.length === 0
    ) {
      return [];
    }

    return quants.records;
  }

  private odooPoToRoutecard(
    po: ReadProduction,
    allQualityChecks: QualityCheck[]
  ) {
    const productName = name(po.product_id)!;
    const extProduct = {
      id: id(po.product_id),
      name: getProductNameWithoutInternalReference(productName),
      internalReference: getProductInternalReferenceFromName(productName),
    } as Product;

    const extBom = {
      id: id(po.bom_id),
      product: extProduct,
    } as BillOfMaterial;

    const serial: ProductSerial = {
      product: extProduct,
      serial: undefined,
    } as ProductSerial;

    if (
      po.lot_producing_id !== undefined &&
      Array.isArray(po.lot_producing_id)
    ) {
      serial.id = po.lot_producing_id[0];
      serial.serial = po.lot_producing_id[1];

      if (isBlank(serial.serial)) {
        console.warn("Serial is empty", JSON.stringify(po.lot_producing_id));
      }
    }

    const qualityChecks2: QualityCheck[] = (po.check_ids || [])
      .map((check_id) => {
        const qc: QualityCheck = {
          id: check_id,
        } as QualityCheck;
        return qc;
      })
      .map((q) => {
        //Find the quality check in the provided (full) list
        const foundQualityCheck = (allQualityChecks || [])
          .map((qc) => qc as QualityCheck)
          .find((qc) => qc.id === q.id);
        if (foundQualityCheck !== undefined) {
          return foundQualityCheck;
        } else {
          //Not found, just use the one with only id filled in
          return q;
        }
      });

    const lines: ProductionOrderLine[] = (po.move_raw_ids || []).map(
      (move_raw_id) => {
        const line: ProductionOrderLine = {
          id: move_raw_id,
        } as ProductionOrderLine;
        return line;
      }
    );

    const po2: ProductionOrder = {
      id: po.id,
      name: po.name,
      origin: po.origin as string,
      bom: extBom,
      bomType: BillOfMaterialType.Manufacture,
      product: extProduct,
      quantity: po.product_qty,
      //reference: po.name || po.display_name,
      date_planned_start: parseOdooDatetime(po.date_planned_start),
      lines: lines,
      state: po.state as MrpProductionState,
      finishedSerial: serial,
      qualityChecks: qualityChecks2,
      location_src_id: {
        id: id(po.location_src_id) || -1,
        name: name(po.location_src_id) || "",
      },
      location_dest_id: {
        id: id(po.location_dest_id) || -1,
        name: name(po.location_dest_id) || "",
      },
      quality_check_fail: po.quality_check_fail,
      quality_check_todo: po.quality_check_todo,
      move_finished_ids: po.move_finished_ids?.map((id) => {
        return { id: id } as StockMove;
      }),
      unbuild_ids: po.unbuild_ids,
      workorder_ids: po.workorder_ids,
    };
    return po2;
  }

  public async readProductionOrderLineSerials(
    po_id: number, // The production order id
    productFilter: string[] //BF numbers of products to filter on. All other products will be ignored
  ): Promise<ProductSerial[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const pos = await odooClient.read<ReadProduction[]>(
      session,
      [[po_id], ["move_raw_ids", "product_id"]],
      "mrp.production"
    );

    const moveRawIds = pos[0].move_raw_ids;
    const stockMoves = await odooClient.read<OdooStockMove[]>(
      session,
      [moveRawIds, ["move_line_ids", "product_id"]],
      "stock.move"
    );

    const moveLineIds = stockMoves
      .filter((sm) =>
        productFilter.includes(
          getProductInternalReferenceFromName(
            name(sm.product_id as any as IdWithName)!
          )!
        )
      )
      .map((sm) => sm.move_line_ids as any as number[])
      .flat()
      .map((smlId) => id(smlId))
      .filter((smlId) => smlId !== undefined && smlId !== null)
      .map((smlId) => smlId!);

    const stockMoveLines = await odooClient.read<StockMoveLine[]>(
      session,
      [
        moveLineIds,
        ["lot_id", "move_id", "product_id", "production_id", "qty_done"],
      ],
      "stock.move.line"
    );

    const lotIds = stockMoveLines
      .filter((line) => line.qty_done !== 0)
      .map((line) => id(line.lot_id))
      .filter((lotId) => lotId !== undefined)
      .map((lotId) => lotId!);

    return await this.readSerials(lotIds);
  }

  public async readProductionOrder(
    po_id: number,
    options: {
      readLot?: boolean;
      readStockMoves?: boolean;
      readQualityChecks?: boolean;
    }
  ): Promise<ProductionOrder> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is udnefined");
    }

    const session = await this.getOdooSession()!;

    const odooPo = await odooClient.readProductionOrder(session, po_id);
    if (odooPo === undefined) {
      throw new Error(`Unable to read PO ${po_id}`);
    }
    console.log("production Order", id(odooPo), JSON.stringify(odooPo));

    const odooProduct = await odooClient.readProduct(
      session,
      id(odooPo.product_id)!
    );
    console.log("Product", odooPo.product_id[0], JSON.stringify(odooProduct));

    const bom_id = odooPo.bom_id[0];

    const stockMoves: Promise<ProductionOrderLine[]> =
      options?.readStockMoves === true
        ? this.readStockMoves2(odooClient, session, odooPo.move_raw_ids)
        : Promise.resolve(
            (odooPo.move_raw_ids || []).map((moveRawId) => {
              return {
                id: moveRawId,
                serial: {} as ProductSerial,
              } as ProductionOrderLine;
            })
          );

    const extProduct = odooProductToRoutecardProduct(odooProduct);
    const extBom = {
      id: bom_id,
      bomType: BillOfMaterialType.Manufacture,
      product: extProduct,
    } as BillOfMaterial;

    const check_ids = odooPo.check_ids as any as number[];
    const qualityChecks: Promise<QualityCheck[]> =
      check_ids !== undefined &&
      Array.isArray(check_ids) &&
      options?.readQualityChecks === true
        ? this.readQualityChecks(check_ids)
        : Promise.resolve(
            (check_ids || []).map((checkId) => {
              return { id: checkId } as QualityCheck;
            })
          );

    let serial: ProductSerial = {
      product: extProduct,
      id: id(odooPo.lot_producing_id),
      serial: name(odooPo.lot_producing_id),
    } as ProductSerial;
    if (
      odooPo.lot_producing_id !== undefined &&
      Array.isArray(odooPo.lot_producing_id) &&
      options?.readLot === true
    ) {
      const s = await this.readSerial(odooPo.lot_producing_id[0]);
      if (s !== undefined) {
        const clone: ProductSerial = { ...s, product: extProduct };
        serial = clone;
      }
    }

    const po2: ProductionOrder = {
      id: po_id,
      name: odooPo.name || `WH/MO/${id}`,
      bom: extBom,
      bomType: BillOfMaterialType.Manufacture,
      product: extProduct,
      quantity: odooPo.product_qty,
      origin: odooPo.origin as string,
      date_planned_start: parseOdooDatetime(odooPo.date_planned_start),
      lines: await stockMoves,
      state: odooPo.state as MrpProductionState,
      qualityChecks: await qualityChecks,
      finishedSerial: serial,
      location_src_id: {
        id: id(odooPo.location_src_id) || -1,
        name: name(odooPo.location_src_id) || "",
      },
      location_dest_id: {
        id: id(odooPo.location_dest_id) || -1,
        name: name(odooPo.location_dest_id) || "",
      },
    };
    return po2;
  }

  private async readStockMoves2(
    odooClient: OdooClient,
    session: any,
    stockMoveIds: number[]
  ): Promise<ProductionOrderLine[]> {
    if (stockMoveIds === undefined || stockMoveIds.length === 0) {
      return [];
    }

    const stockMoves = await odooClient.readStockMoves(session, stockMoveIds);
    const product_ids = (stockMoves || []).map((sm) => id(sm.product_id)!);
    const odooBomProducts = (
      await odooClient.readProducts(session, product_ids)
    ).map((pr) => odooProductToRoutecardProduct(pr));

    const lines: ProductionOrderLine[] = stockMoves.map((sm) => {
      const product = odooBomProducts.find((pr) => pr.id === sm.product_id[0]);
      if (!product) {
        const msg = `Could not find product with ID '${sm.product_id[0]}' in Odoo`;
        console.error(msg);
        throw new Error(msg);
      }
      const serial: ProductSerial = {
        product: product,
      } as ProductSerial;
      if (sm.lot_ids !== undefined && sm.lot_ids.length === 1) {
        serial.id = id(sm.lot_ids)!;
      }

      const extLine: ProductionOrderLine = {
        id: sm.id,
        bom_line_id: sm.bom_line_id[0],
        company_id: sm.company_id[0],
        product: product,
        sequence: sm.sequence,
        product_qty: sm.product_qty,
        quantity_done: sm.quantity_done as any,
        serial: serial,
        move_line_ids: sm.move_line_ids,
      };
      return extLine;
    });

    const lot_ids = lines
      .filter((l) => l.serial !== undefined)
      .map((l) => l.serial as ProductSerial)
      .filter((s) => s.id > 0)
      .map((s) => s.id);
    const odooSerials = await odooClient.readSerials(session, lot_ids);

    for (const line of lines) {
      const extSerial = line.serial as ProductSerial;
      if (extSerial !== undefined && extSerial.id > 0) {
        const odooSerial = odooSerials.find((s) => s.id === extSerial.id);
        if (odooSerial !== undefined) {
          const convertedSerial = odooSpltoProductSerial(odooSerial);
          extSerial.company_id = convertedSerial.company_id;
          extSerial.serial = convertedSerial.serial;
          extSerial.deviceId = convertedSerial.deviceId;
          extSerial.udi = convertedSerial.udi;
          extSerial.creationDate = convertedSerial.creationDate;
        }
      }
    }

    return lines;
  }

  public async readStockMoves3(
    stockMoveIds: number[]
  ): Promise<ProductionOrderLine[]> {
    if (stockMoveIds === undefined || stockMoveIds.length === 0) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const stockMoves = await odooClient.readStockMoves(session, stockMoveIds);
    const product_ids = (stockMoves || []).map((sm) => id(sm.product_id)!);
    const odooBomProducts = (
      await odooClient.readProducts(session, product_ids)
    ).map((pr) => odooProductToRoutecardProduct(pr));

    const lines: ProductionOrderLine[] = stockMoves.map((sm) => {
      const product = odooBomProducts.find((pr) => pr.id === sm.product_id[0]);
      if (!product) {
        const msg = `Could not find product with ID '${sm.product_id[0]}' in Odoo`;
        console.error(msg);
        throw new Error(msg);
      }
      const serial: ProductSerial = {
        product: product,
      } as ProductSerial;
      if (sm.lot_ids !== undefined && sm.lot_ids.length === 1) {
        serial.id = id(sm.lot_ids)!;
      }

      const extLine: ProductionOrderLine = {
        id: sm.id,
        bom_line_id: sm.bom_line_id[0],
        company_id: sm.company_id[0],
        product: product,
        sequence: sm.sequence,
        product_qty: sm.product_qty,
        quantity_done: sm.quantity_done as any,
        serial: serial,
        move_line_ids: sm.move_line_ids,
      };
      return extLine;
    });

    const lot_ids = lines
      .filter((l) => l.serial !== undefined)
      .map((l) => l.serial as ProductSerial)
      .filter((s) => s.id > 0)
      .map((s) => s.id);
    const odooSerials = await odooClient.readSerials(session, lot_ids);

    for (const line of lines) {
      const extSerial = line.serial as ProductSerial;
      if (extSerial !== undefined && extSerial.id > 0) {
        const odooSerial = odooSerials.find((s) => s.id === extSerial.id);
        if (odooSerial !== undefined) {
          const convertedSerial = odooSpltoProductSerial(odooSerial);
          extSerial.company_id = convertedSerial.company_id;
          extSerial.serial = convertedSerial.serial;
          extSerial.deviceId = convertedSerial.deviceId;
          extSerial.udi = convertedSerial.udi;
          extSerial.creationDate = convertedSerial.creationDate;
        }
      }
    }

    return lines;
  }

  public async searchProductionOrderBySerialId(
    serialId: number,
    includeUnbuilt?: boolean
  ): Promise<ProductionOrder | null> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    if (serialId === undefined) {
      throw Error("Serial ID is undefined");
    }

    const session = await this.getOdooSession()!;

    const domain: OdooDomainPart[] = [
      ["lot_producing_id", "=", serialId],
      ["state", "=", "done"],
    ];

    if (!includeUnbuilt) {
      domain.push(["unbuild_ids", "=", false]);
    }

    const pos = (
      await odooClient.searchRead<ReadProduction>(
        session,
        domain,
        odooClient.mrp_production_fields,
        undefined,
        1,
        "id desc",
        "mrp.production"
      )
    ).records;
    if (pos.length === 0) {
      return null;
    } else {
      return this.odooPoToRoutecard(pos[0], []);
    }
  }

  public async searchProductionOrderBySerials(
    serials: ProductSerial[]
  ): Promise<ProductionOrder[]> {
    if (serials === undefined || serials.length === 0) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    const lot_ids = serials.map((s) => s.id);

    const pos = await this.searchProductionOrdersByLotIds(
      odooClient,
      session,
      lot_ids
    );

    return pos;
  }

  public async traceProductionOrderBySerial(
    serial: ProductSerial | undefined,
    productFilter: string[] //BF numbers of products to filter on. All other products will be ignored
  ): Promise<ProductionOrderTrace[]> {
    const trace: ProductionOrderTrace[] = [];
    if (serial === undefined) {
      return [];
    } else if (isNaN(serial.id)) {
      throw new Error("Serial does not have an odoo id");
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }
    const session = await this.getOdooSession()!;

    const pos = await odooClient.searchRead<ReadProduction>(
      session,
      [["lot_producing_id", "=", serial.id]],
      odooClient.mrp_production_fields,
      undefined,
      10,
      undefined,
      "mrp.production"
    );

    if (pos === undefined || pos.length === 0) {
      return [];
    } else if (pos.length === 1 && pos.records !== undefined) {
      const odooPo = pos.records[0];

      const stockMoves = await odooClient.readStockMoves(
        session,
        odooPo.move_raw_ids
      );

      const children_lot_ids = stockMoves
        .filter((move) => Array.isArray(move.lot_ids))
        .map((move) => move.lot_ids)
        .flat();

      const children: ProductionOrder[] =
        await this.searchProductionOrdersByLotIds(
          odooClient,
          session,
          children_lot_ids
        );

      for (const sm of stockMoves) {
        const f = children.find(
          (po) => (po.finishedSerial as ProductSerial).id === sm.lot_ids[0]
        );

        const p = productRefToRoutecardProduct(sm.product_id);
        let serial: ProductSerial = {
          product: p,
        } as ProductSerial;

        if (sm.lot_ids !== undefined && sm.lot_ids.length === 1) {
          serial.id = sm.lot_ids[0];
          serial.serial = "serial-" + sm.lot_ids[0];
          if (f !== undefined && f.finishedSerial !== undefined) {
            serial = f.finishedSerial as ProductSerial;
          }

          const loc: Location = {
            id: sm.location_dest_id[0],
            name: sm.location_dest_id[1],
          };
          trace.push({
            serial: serial,
            product: p,
            location: loc,
            po: f,
          });
        }
      }

      const grandChildren = children.map((child) => child.id);

      const grandChildrenStockMoves =
        await odooClient.searchRead<StockMoveLine>(
          session,
          [
            "&",
            ["production_id", "in", grandChildren],
            ["product_id.default_code", "in", productFilter],
          ],
          [
            "production_id",
            "move_id",
            "lot_id",
            "lot_name",
            "qty_done",
            "product_id",
            "location_id",
            "location_dest_id",
          ],
          undefined,
          100,
          undefined,
          "stock.move.line"
        );

      for (const grandChild of grandChildrenStockMoves.records) {
        const product = productRefToRoutecardProduct(
          grandChild.product_id as any as IdWithName
        );
        const s: ProductSerial = {
          product: product,
          id: (grandChild.lot_id as any as IdWithName)[0],
          serial: (grandChild.lot_id as any as IdWithName)[1],
        } as ProductSerial;

        const loc = grandChild.location_dest_id as any as IdWithName;
        const location: Location = {
          id: loc === undefined ? -1 : loc[0],
          name: loc === undefined ? "" : loc[1],
        };
        const traceGrandChild: ProductionOrderTrace = {
          serial: s,
          product: product,
          location: location,
          po: undefined,
        };

        trace.push(traceGrandChild);
      }

      return trace;
    } else {
      return [];
    }
  }

  private async searchProductionOrdersByLotIds(
    odooClient: OdooClient,
    session: Session,
    lot_ids: number[]
  ): Promise<ProductionOrder[]> {
    if (odooClient === undefined) {
      throw new Error();
    }

    const mos = await odooClient.searchRead<ReadProduction>(
      session,
      [["lot_producing_id", "in", lot_ids]],
      odooClient.mrp_production_fields,
      undefined,
      10,
      undefined,
      "mrp.production"
    );
    if (mos !== undefined && mos.length > 0) {
      return mos.records.map((mo) => this.odooPoToRoutecard(mo, []));
    } else {
      return [];
    }
  }

  public async searchQualityPointsByProduct(
    product: Product
  ): Promise<QualityPoint[]> {
    if (!this.isOdooProduct(product)) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const points = await odooClient.searchRead<QualityPoint>(
      session,
      [["product_ids", "in", product.id]],
      [
        "sequence",
        "name",
        "title",
        "product_ids",
        "picking_type_ids",
        "operation_id",
        "measure_frequency_type",
        "test_type_id",
        "team_id",
        "user_id",
        "company_id",
      ],
      undefined,
      10,
      undefined,
      "quality.point"
    );
    if (
      points !== undefined &&
      points.length > 0 &&
      points.records !== undefined
    ) {
      return points.records;
    } else {
      return [];
    }
  }

  private filterToDomain(filter: SerialFilter): OdooDomainPart[] {
    const parts: OdooDomainPart[] = [];
    if (isNotBlank(filter.ref)) {
      parts.push(["ref", "=", filter.ref]);
    }

    if (isNotBlank(filter.serial)) {
      parts.push(["name", "=", filter.serial]);
    }

    if (isNotBlank(filter.versionNumber)) {
      parts.push(["product.name", "ilike", filter.versionNumber]);
    }

    const refs: string[] = filter.productRefs
      ? filter.productRefs.filter((ref) => ref)
      : [];
    if (refs.length > 0) {
      parts.push(["product_id.default_code", "in", refs]);
    }

    if (parts.length === 0) {
      throw new Error("No filter to apply");
    } else if (parts.length === 1) {
      return parts;
    } else if (parts.length === 2) {
      return ["&", parts[0], parts[1]];
    } else if (parts.length === 3) {
      return ["&", "&", parts[0], parts[1], parts[2]];
    } else if (parts.length === 4) {
      return ["&", "&", "&", parts[0], parts[1], parts[2], parts[3]];
    } else {
      throw new Error("Too many filters to apply");
    }
  }
  public async searchSerialsByName(
    filter: SerialFilter
  ): Promise<ProductSerial[]> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const domain = this.filterToDomain(filter);
    const lots = await odooClient.searchRead<RouteCardReadProductionLot>(
      session,
      domain,
      ["name", "ref", "product_id", "create_date", "company_id", "routecard_servicing"],
      undefined,
      10,
      undefined,
      "stock.lot" as OdooModel
    );
    if (lots !== undefined && lots.length > 0) {
      return lots.records.map((lot) => odooSpltoProductSerial(lot));
    } else {
      return [];
    }
  }

  public async getSerialsForProducts(
    products: Product[]
  ): Promise<ProductSerial[]> {
    if (products === undefined) {
      throw new Error("Invalid argument: products");
    }
    if (products.length === 0) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const domain: OdooDomainPart[] = [
      "&",
      ["product_id", "in", products.map((p) => p.id)],
      // DKSB-1771 do not show lots that were marked as scrapped
      ["name", "not ilike", " - scrapped"],
      ["name", "not ilike", " - depleted"],
      ["name", "not ilike", " - retired"],
    ];

    const lots = await odooClient.searchRead<RouteCardReadProductionLot>(
      session,
      domain,
      ["name", "ref", "product_id", "create_date", "company_id"],
      undefined,
      1000,
      undefined,
      "stock.lot" as OdooModel
    );
    if (lots === undefined || lots.records === undefined || lots.length === 0) {
      return [];
    }

    const serials = lots.records.map((lot) => odooSpltoProductSerial(lot));
    return serials;
  }
  public async searchSerialsForProducts(
    products: GetProduct[]
  ): Promise<ProductSerial[]> {
    if (products === undefined) {
      throw new Error(`products is undefined`);
    }
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const productIds = products
      .map((p) => id(p))
      .filter((i) => i !== undefined && !isNaN(i))
      .map((i) => i!);

    if (productIds.length === 0) {
      return [];
    }

    const session = await this.getOdooSession()!;

    const domain: OdooDomainPart[] = [["product_id", "in", productIds]];
    const lots = await odooClient.searchRead<RouteCardReadProductionLot>(
      session,
      domain,
      ["name", "ref", "product_id", "create_date", "company_id"],
      undefined,
      10000,
      undefined,
      "stock.lot" as OdooModel
    );
    if (lots === undefined || lots.records === undefined || lots.length === 0) {
      return [];
    }

    const serials = lots.records.map((lot) => odooSpltoProductSerial(lot));
    return serials;
  }

  public async readSerials(lot_ids: number[]): Promise<ProductSerial[]> {
    if (lot_ids === undefined || lot_ids.length === 0) {
      return [];
    }
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const stockProductionLotFields = [
      "name",
      "ref",
      "product_id",
      "create_date",
      "company_id",
    ];

    const result = await odooClient.read<RouteCardReadProductionLot[]>(
      session,
      [lot_ids, stockProductionLotFields],
      "stock.lot" as OdooModel
    );
    if (result === undefined || result.length === 0) {
      return [];
    }

    const serials = result.map((lot) => odooSpltoProductSerial(lot));
    return serials;
  }

  public async readSerial(lot_id: number): Promise<ProductSerial | undefined> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const lot = await odooClient.readStockProductionLot(session, lot_id);
    if (lot !== undefined) {
      return odooSpltoProductSerial(lot);
    } else {
      return undefined;
    }
  }

  public async readProduct(product_id: number): Promise<Product | undefined> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const product = await odooClient.readProduct(session, product_id);
    if (product !== undefined) {
      return odooProductToRoutecardProduct(product);
    } else {
      return undefined;
    }
  }

  public async scrapProductionOrder(
    productionOrder: ProductionOrder,
    sourceLocation: number,
    scrapLocation: number
  ) {
    const product = productionOrder.product as ExtendedProduct;
    const serial = productionOrder.finishedSerial as ProductSerial;

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;
    let odooPo = await odooClient.readProductionOrder(
      session,
      productionOrder.id
    );
    if (odooPo === undefined) {
      throw new Error();
    }

    await this.deleteAllQualityChecks(odooClient, session, odooPo);

    if (
      odooPo.state === "confirmed" ||
      odooPo.state === "progress" ||
      odooPo.state === "to_close"
    ) {
      await odooClient.callButtonIds(
        session,
        "mrp.production",
        "button_mark_done",
        [odooPo.id!]
      );
    }

    // //Re-read the state from odoo
    // odooPo = await odooClient.readProductionOrder(
    //   session,
    //   extProductionOrder.id
    // );
    // if (odooPo === undefined) {
    //   throw new Error();
    // }

    //if (odooPo.state === "done") {
    const scrap: StockScrap = {
      product_id: product.id as any,
      scrap_qty: productionOrder.quantity,
      lot_id: serial === undefined ? undefined : (serial.id as any),
      company_id: product.company_id,
      location_id: sourceLocation,
      scrap_location_id: scrapLocation,
      product_uom_id: 1,
      name: "scrap",
    } as any as StockScrap;
    const scrap2 = await this.createStockScrap(scrap);
    const scrapId = scrap2.id!;
    await this.validateStockScrap(scrapId, scrap);
    // } else {
    //   throw new Error("Unable to decide how to scrap this PO");
    // }
  }

  public async scrapSerial(
    serial: ProductSerial,
    sourceLocation: number,
    scrapLocation: number
  ) {
    const product = serial.product as ExtendedProduct;

    if (!this.isOdooProductSerial(serial)) {
      throw new Error("Serial is undefined");
    } else if (!this.isOdooProduct(product)) {
      throw new Error("product is undefined");
    }

    const scrap: StockScrap = {
      product_id: product.id as any,
      scrap_qty: 1,
      lot_id: serial.id,
      company_id: product.company_id,
      location_id: sourceLocation,
      scrap_location_id: scrapLocation,
      product_uom_id: 1,
      name: "scrap",
    } as any as StockScrap;
    const scrap2 = await this.createStockScrap(scrap);
    const scrapId = scrap2.id!;
    await this.validateStockScrap(scrapId, scrap);
    return scrapId;
  }

  private async deleteAllQualityChecks(
    odooClient: OdooClient,
    session: any,
    odooPo: ReadProduction
  ) {
    if (
      odooClient === undefined ||
      session === undefined ||
      odooPo === undefined
    ) {
      throw new Error();
    }

    const check_ids_to_delete: number[] = [];
    if (
      odooPo.quality_check_todo === true &&
      odooPo.check_ids !== undefined &&
      odooPo.check_ids.length > 0
    ) {
      //There are still quality checks todo, loop over them and delete them all
      const checks = await odooClient.readQualityChecks(
        session,
        odooPo.check_ids
      );

      for (const check of checks) {
        if (check !== undefined && check.quality_state === "none") {
          check_ids_to_delete.push(check.id!);
        }
      }
      await odooClient.unlink(session, check_ids_to_delete, "quality.check");
    }

    //Re-read the state from odoo
    const odooPo2 = await odooClient.readProductionOrder(session, odooPo.id);
    if (odooPo2 === undefined) {
      throw new Error();
    }

    if (odooPo2.quality_check_todo === true) {
      throw new Error("Failed to close all quality checks");
    }
  }

  public async unbuild(mrpUnbuild: MrpUnbuild, validate: boolean = true) {
    if (mrpUnbuild === undefined) {
      throw new Error();
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    let odooUnbuild = await odooClient.create(
      session,
      [mrpUnbuild],
      "mrp.unbuild"
    );
    if (odooUnbuild === undefined) {
      throw new Error();
    }

    if (validate) {
      await odooClient.callButtonIds(
        session,
        "mrp.unbuild",
        "action_validate",
        [odooUnbuild]
      );
    }
    return odooUnbuild;
  }

  public async createStockPick(
    serials: ProductSerial[],
    sourceLocation: number,
    destLocation: number,
    picking_type_id: number = 5, // Internal transfer
    validate: boolean = true
  ) {
    for (const serial of serials) {
      const product = serial.product;
      if (!this.isOdooProductSerial(serial)) {
        throw new Error("Serial is undefined");
      } else if (!this.isOdooProduct(product)) {
        throw new Error("product is undefined");
      }
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error();
    }

    const session = await this.getOdooSession()!;

    const lines: AddNewRecordCommand<StockMoveLine>[] = serials
      .map((serial) => {
        const product = serial.product;
        const stockMoveLine: StockMoveLine = {
          product_id: product.id,
          company_id: id(product.company_id) || COMPANY_ID_BYTEFLIES,
          location_id: sourceLocation,
          location_dest_id: destLocation,
          lot_id: serial.id,
          qty_done: 1,
          product_uom_id: 1 as any,
        } as StockMoveLine;
        return stockMoveLine;
      })
      .map((line) => [0, 0, line]);

    const stockMove: StockPicking = {
      move_type: "direct",
      scheduled_date: "",
      company_id: id(session.user.company_id) || COMPANY_ID_BYTEFLIES,
      location_id: sourceLocation as Many2One<CreateLocationArgs>,
      location_dest_id: destLocation,
      picking_type_id: picking_type_id as any,
      move_line_ids_without_package: lines,
      priority: "0",
      immediate_transfer: true,
    } as StockPicking;
    const spId = await odooClient.create(session, [stockMove], "stock.picking");

    await odooClient.callButtonIds(session, "stock.picking", "action_confirm", [
      spId,
    ]);
    if (validate) {
      await odooClient.callButtonIds(
        session,
        "stock.picking",
        "button_validate",
        [spId]
      );
    }

    for (const serial of serials) {
      if (serial.id !== undefined) {
        const stockPickings = await odooClient.read<StockPicking[]>(
          session,
          [[spId], ["check_ids"]],
          "stock.picking"
        );
        if (stockPickings !== undefined) {
          for (const stockPicking of stockPickings) {
            const check_ids = stockPicking.check_ids;
            if (check_ids !== undefined) {
              for (const check_id of check_ids) {
                const qc: OdooQualityCheck = {
                  lot_id: serial.id,
                } as OdooQualityCheck;
                await odooClient.write(
                  session,
                  check_id as any as number,
                  qc,
                  "quality.check"
                );
              }
              stockMove.check_ids = (stockMove.check_ids ?? []).concat(
                check_ids
              );
            }
          }
        }
      }
    }
    stockMove.id = spId;
    return stockMove;
  }

  public async readUnbuildOrder(id: number): Promise<MrpUnbuild> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const odooPo = await odooClient.read<MrpUnbuild[]>(
      session,
      [id],
      "mrp.unbuild"
    );
    if (odooPo === undefined || odooPo.length !== 1) {
      throw new Error(`Unable to read unbuild order ${id}`);
    }

    return odooPo[0];
  }

  public async readStockScrap(id: number): Promise<StockScrap> {
    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const odooPo = await odooClient.read<StockScrap[]>(
      session,
      [id],
      "stock.scrap"
    );
    if (odooPo === undefined || odooPo.length !== 1) {
      throw new Error(`Unable to read scrap order ${id}`);
    }

    return odooPo[0];
  }

  public async readBoms(bom_ids: number[]): Promise<GetBillOfMaterial[]> {
    if (!Array.isArray(bom_ids) || bom_ids.length === 0) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const result = await odooClient.read<GetBillOfMaterial[]>(
      session,
      [bom_ids, ["bom_line_ids", "product_id", "product_qty", "type", "code"]],
      "mrp.bom"
    );
    return result;
  }

  public async createProductionOrders(
    product: GetProduct,
    bom: GetBillOfMaterial,
    amount: number,
    productionDate: Date | undefined
  ) {
    let numberOfPos = amount;
    let productQty = 1;

    //For lot-based products we prefer one PO with multiple products in it
    if (product.tracking === "lot") {
      numberOfPos = 1;
      productQty = amount;
    }

    const pos: MyProductionOrder[] = [];
    for (let i = 0; i < numberOfPos; i++) {
      const po = await this.createPoForProduct(
        product,
        bom,
        productQty,
        productionDate
      );
      pos.push(po);
      // if (po !== undefined) {

      //   //We have created the parent PO, now see if we also need to create child PO's for
      //   // if (po.po !== undefined) {
      //   //   const childPos = await this.createChildPos(po.po, productionDate);
      //   //   if (childPos.length > 0) {
      //   //     for (const childPo of childPos) {
      //   //       pos.push(childPo);
      //   //     }
      //   //   }
      //   // }
      // }
    }
    const proc: ProcurementGroup = {
      display_name: undefined as any,
      move_type: "direct",
      name: "",
      mrp_production_ids: pos.map(
        (po) => [0, 0, po.po] as AddNewRecordCommand<OdooProductionOrder>
      ) as any,
    };

    const info: ProcurementGroupWithInfo = {
      products: pos.map((po) => po.product),
      group: proc,
    };
    return info;
  }

  private async createPoForProduct(
    product: GetProduct,
    bom: GetBillOfMaterial,
    qty: number,
    productionDate: Date | undefined
  ) {
    if (product.id === undefined || product.tracking === undefined) {
      throw new Error("Invalid product: " + product);
    }

    // const bom_ids = product.bom_ids as any[] as number[];
    // if (
    //   bom_ids === undefined ||
    //   !Array.isArray(bom_ids) ||
    //   bom_ids.length === 0
    // ) {
    //   console.log(
    //     "No BoM's found for product: " + product.id + " " + product.name
    //   );
    //   return undefined;
    // }
    // const normalBoms = (await this.readBoms(bom_ids)).filter(
    //   (b) => b.type === "normal"
    // );
    // if (normalBoms.length === 0) {
    //   console.log(
    //     "No BoM's of normal type found for product: " +
    //       product.id +
    //       " " +
    //       product.name
    //   );
    //   return undefined;
    // } else if (normalBoms.length > 1) {
    //   console.log(
    //     "Multiple BoM's of normal type found for product: " +
    //       product.id +
    //       " " +
    //       product.name
    //   );
    //   return undefined;
    // }
    // const bom = normalBoms[0];
    // const bom_line_ids = bom.bom_line_ids;
    // if (bom_line_ids === undefined || bom_line_ids.length === 0) {
    //   console.log(
    //     "No BoM lines found for product: " +
    //       product.id +
    //       " " +
    //       product.name +
    //       " " +
    //       bom_ids
    //   );
    //   return undefined;
    // }

    const bom_lines = await this.readBomLines(bom.bom_line_ids);
    const po = createOdooProductionOrder(
      product,
      qty,
      bom.id,
      bom_lines,
      productionDate
    );

    const myPo: MyProductionOrder = {
      po: po,
      product: product,
      bom: bom,
      bom_lines: bom_lines,
    };
    return myPo;
  }

  private async readBomLines(bom_line_ids: number[]) {
    if (!Array.isArray(bom_line_ids) || bom_line_ids.length === 0) {
      return [];
    }

    const odooClient = this.getOdooClient();
    if (odooClient === undefined) {
      throw new Error("Odoo client is undefined");
    }

    const session = await this.getOdooSession()!;

    const result = await odooClient.read<ReadBomLine[]>(
      session,
      [
        bom_line_ids,
        ["id", "bom_id", "product_id", "product_tmpl_id", "product_qty"],
      ],
      "mrp.bom.line"
    );
    return result;
  }
}

export function odooSpltoProductSerial(lot: RouteCardReadProductionLot) {
  const serial = lot.name;
  const productRef = productRefToRoutecardProduct(lot.product_id);
  const udi = serialToUdi(lot, productRef);

  const s: ProductSerial = {
    id: id(lot.id)!,
    serial: serial,
    deviceId: lot.ref || "",
    udi: udi || serial,
    product: productRef,
    company_id: id(lot.company_id)!,
    routecard_servicing: lot.routecard_servicing,
  };

  if (isSerial(s.serial)) {
    const d = parseDateFromSerial(s.serial!);
    if (isNotBlank(d)) {
      s.creationDate = new Date(d);
    } else {
      throw new Error("Unable to parse date");
    }
  } else if (isLot(s.serial)) {
    const d = parseDateFromLot(s.serial!);
    if (isNotBlank(d)) {
      s.creationDate = new Date(d);
    } else {
      throw new Error("Unable to parse production date");
    }
  }
  return s;
}

function serialToUdi(lot: ReadProductionLot, productRef: Product) {
  const serial = lot.name;
  const deviceId = lot.ref;
  if (deviceId !== false && isNotBlank(deviceId) && deviceId.startsWith("01")) {
    try {
      const parsedUdi = parseSerial(deviceId);
      if (
        parsedUdi !== undefined &&
        isNotBlank(parsedUdi.gtin) &&
        isNotBlank(parsedUdi.udi)
      ) {
        return parsedUdi.udi;
      }
    } catch (error) {}
  }
  if (
    productRef !== undefined &&
    serial !== undefined &&
    isNotBlank(productRef.internalReference)
  ) {
    const gtin = getGtinByInternalReference(productRef.internalReference);
    const tracking = productRef?.inventory?.traceability?.tracking;

    if (isNotBlank(gtin)) {
      if (tracking === Tracking.ByUniqueSerialNumber || isSerial(serial)) {
        return `01${gtin}21${serial}`;
      } else if (tracking === Tracking.ByLots || isLot(serial)) {
        return `01${gtin}10${serial}`;
      }
    }
  }
  return undefined;
}

export function createOdooProductionOrder(
  product: GetProduct,
  product_qty: number,
  bom_id: number,
  bom_lines: ReadBomLine[],
  productionDate: Date | undefined
) {
  const uom_units = [1, "Units"];
  const company_byteflies = [1, "Byteflies"];
  const company_id = company_byteflies[0] as number;
  const warehouse_id = WH_BYTEFLIES_MANUFACTURING;

  // This is the stock move of the finished good
  const moveFinished = {
    byproduct_id: false,
    company_id: company_id,
    group_id: false,
    // the finished good is moved from virt/production to
    // the destination location (mostly stock)
    location_id: VIRT_PRODUCTION,
    location_dest_id: WH_STOCK,
    move_dest_ids: [[6, false, []] as any],
    name: "New",
    operation_id: false,
    origin: "New",
    picking_type_id: 8,
    product_id: product.id,
    product_uom: 1,
    product_uom_qty: 1,
    propagate_cancel: false,
    warehouse_id: warehouse_id,
  } as OdooStockMove;

  const move_finished_ids: AddNewRecordCommand<OdooStockMove>[] = [
    [0, 0, moveFinished],
  ];

  const bom_lines_clone = modifyBomLines(bom_lines, product_qty);
  const move_raw_ids: Many2Many<OdooStockMove> = bom_lines_clone.map(
    (bom_line): AddNewRecordCommand<OdooStockMove> => {
      const c: AddNewRecordCommand<OdooStockMove> = [
        0,
        0,
        {
          additional: false,
          bom_line_id: bom_line.id,
          company_id: company_id,
          date:
            productionDate === undefined
              ? undefined
              : dateToOdooDateTime(productionDate),
          location_id: WH_STOCK,
          location_dest_id: VIRT_PRODUCTION,
          lot_ids: [[6, 0, []] as ReplaceAllExistingRecordsCommand],
          move_line_ids: [],
          name: "New",
          operation_id: false,
          picking_type_id: 8,
          product_id: bom_line.product_id[0],
          product_uom: 1,
          product_uom_qty: bom_line.product_qty,
          quantity_done: 0,
          sequence: bom_line.sequence,
          state: "draft",
          warehouse_id: warehouse_id,
          origin: undefined,
        } as OdooStockMove,
      ];
      return c;
    }
  );

  const po: OdooProductionOrder = {
    product_id: product.id as number,
    bom_id: bom_id,
    company_id: company_id,
    product_qty: product_qty,
    origin: undefined,
    // the location to take components from
    location_src_id: WH_STOCK,
    // the location to move the finished good to
    location_dest_id: WH_STOCK,
    product_uom_id: uom_units[0] as number, // Units
    move_finished_ids: move_finished_ids,
    move_raw_ids: move_raw_ids,
    // message_ids: [],
    lot_producing_id: false as any,
    qty_producing: 0,
  } as OdooProductionOrder;

  if (productionDate !== undefined) {
    po.date_planned_start = dateToOdooDateTime(productionDate);
    po.date_planned_finished = dateToOdooDateTime(productionDate);
  }

  return po;
}

function modifyBomLines(bom_lines: ReadBomLine[], amount: number) {
  let bom_lines_clone = [...bom_lines];

  for (const line of bom_lines_clone) {
    line.product_qty = line.product_qty * amount;
  }

  return bom_lines_clone;
}

export function getInternalReference(
  product_id: IdWithName
): string | undefined {
  if (Array.isArray(product_id)) {
    return getInternalReferenceFromName(product_id[1]);
  }
  return undefined;
}

function getInternalReferenceFromName(name: string): string | undefined {
  if (name !== undefined) {
    const open = name.indexOf("[");
    const close = name.indexOf("]");
    if (open !== -1 && close !== -1 && open < close) {
      return name.substring(open + 1, close).trim();
    }
  }
  return undefined;
}
